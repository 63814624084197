import React, { useState, useEffect } from "react"
import {
  FormLayout,
  TextField,
  Button,
  Icon,
  ButtonGroup,
  LegacyStack,
  RadioButton,
  Link,
  Text,
  Box,
  ChoiceList,
  Popover,
  ActionList,
} from "@shopify/polaris";
import { TextFontIcon, TextAlignCenterIcon, TextAlignLeftIcon, TextAlignRightIcon, PlusIcon, EditIcon, DeleteIcon, DragHandleIcon } from "@shopify/polaris-icons";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import update from "immutability-helper"
import styled from "styled-components"
import { PAYMENT_ICONS, ADDITIONAL_PAYMENT_METHODS } from "../../../../constants/constants";
import CardDivider from '../../../../components/cardDivider';

const colors = [
  {
    label: "Info",
    value: "info",
  },
  {
    label: "Warning",
    value: "warning",
  },
  {
    label: "Success",
    value: "success",
  },
  {
    label: "Critical",
    value: "critical",
  }
]

const TextSizeWrapper = styled.div`
  .extraLarge-size svg {
    width: 130%;
    height: 130%;
    max-width: 130%;
    max-height: 130%;
    transform: translate(-15%, -15%);
  }
  .medium-size .Polaris-Icon {
    padding-top: 2px;
    svg {
      margin-left: 3px;
      width: 90%;
      height: 90%;
      max-width: 90%;
      max-height: 90%;
    }
  }
  .base-size .Polaris-Icon {
    padding-top: 4px;
    svg {
      margin-left: 3px;
      width: 70%;
      height: 70%;
      max-width: 70%;
      max-height: 70%;
    }
  }
  .small-size .Polaris-Icon {
    padding-top: 5px;
    svg {
      margin-left: 4px;
      width: 60%;
      height: 60%;
      max-width: 60%;
      max-height: 60%;
    }
  }
  .extraSmall-size .Polaris-Icon {
    padding-top: 6px;
    svg {
      margin-left: 4px;
      width: 50%;
      height: 50%;
      max-width: 50%;
      max-height: 50%;
    }
  }
`

const CustomChoiceList = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #dfe3e8;
    border-radius: 8px;
    background: white;
    min-height: 48px;
  }
  
  .Polaris-Choice {
    margin-top: 0;
  }

  .Polaris-Choice__Label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .Polaris-ChoiceList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
    margin: -4px;
  }

  .Polaris-Choice__Control {
    top: 0;
    margin-top: 0;
  }

  .Polaris-Choice__Control input[type='radio'],
  .Polaris-Choice__Control input[type='checkbox'] {
    margin-top: 0;
  }

  .payment-method-item {
    display: flex;
    align-items: center;
  }

  .payment-icon {
    width: 32px;
    height: 24px;
    object-fit: contain;
  }

  .payment-name {
    margin-left: 8px;
    font-size: 14px;
    color: #202223;
  }
`;

const CustomActionListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;

  .payment-icon {
    width: 32px;
    height: 24px;
    object-fit: contain;
  }

  .payment-name {
    font-size: 14px;
    color: #202223;
  }
`;

const DraggableItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: white;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  margin-bottom: 8px;

  .drag-handle {
    cursor: grab;
    margin-right: 12px;
    color: #8c9196;
  }

  .payment-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .payment-icon {
    width: 32px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
  }

  .payment-name {
    font-size: 14px;
    color: #202223;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  .action-button {
    padding: 4px;
    border-radius: 4px;
    &:hover {
      background: #f6f6f7;
    }
  }
`;

const ColorList = styled.div`
  .color-list ul {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

const renderChoice = ({ value, label, iconUrl }) => ({
  value,
  label: (
    <div className="payment-method-item">
      <img 
        src={iconUrl} 
        alt={`${label} icon`} 
        className="payment-icon"
      />
      <span className="payment-name">{label}</span>
    </div>
  ),
  iconUrl: iconUrl
});



export default function PaymentIconsContent({ contentEdit, state, setState }) {
  const content = state.contents[contentEdit]
  const [editingIndex, setEditingIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedMethods, setSelectedMethods] = useState(content.paymentIcons || []);
  const [isAddingMore, setIsAddingMore] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedMethods);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedMethods(items);
  };

  const handleDelete = (index) => {
    const newMethods = [...selectedMethods];
    newMethods.splice(index, 1);
    setSelectedMethods(newMethods);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setIsEditModalOpen(true);
  };

  const handleEditSelection = (newMethod) => {
    if (editingIndex !== null) {
      setSelectedMethods(prevMethods => {
        const newMethods = [...prevMethods];
        newMethods[editingIndex] = {
          value: newMethod.value,
          label: newMethod.label,
          iconUrl: newMethod.iconUrl
        };
        return newMethods;
      });
    }
    setIsEditModalOpen(false);
    setEditingIndex(null);
  };

  const handleChoiceListChange = (values) => {
    const newMethods = values.map(value => {
      const method = PAYMENT_ICONS.find(m => m.value === value);
      return {
        value: method.value,
        label: method.label,
        iconUrl: method.iconUrl
      };
    });
    const uniqueNewMethods = newMethods.filter(newMethod => 
      !selectedMethods.some(existing => existing.value === newMethod.value)
    );
    
    setSelectedMethods(prevMethods => [...prevMethods, ...uniqueNewMethods]);
  };

  useEffect(() => {
    setState(
      update(state, {
        contents: {
          [contentEdit]: { paymentIcons: { $set: selectedMethods } },
        },
      })
    )
  }, [selectedMethods])

  console.log(content);

  const actionListItems = ADDITIONAL_PAYMENT_METHODS.map(method => ({
    content: (
      <CustomActionListItem>
        <img
          src={method.iconUrl}
          alt={`${method.label} icon`}
          className="payment-icon"
        />
        <span className="payment-name">{method.label}</span>
      </CustomActionListItem>
    ),
    value: method.value,
    iconUrl: method.iconUrl,
    onAction: () => {
      if (isEditModalOpen) {
        // Handle edit mode
        handleEditSelection(method);
      } else {
        const isDuplicate = selectedMethods.some(
          existing => existing.value === method.value
        );
        
        if (!isDuplicate) {
          const newMethod = {
            value: method.value,
            label: method.label,
            iconUrl: method.iconUrl
          };
          setSelectedMethods(prevMethods => [...prevMethods, newMethod]);
        }
        setIsAddingMore(false);
      }
    }
  }));

  return (
    <FormLayout>
      <div>
        <Box paddingBlockEnd="400">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Heading</Text>
        </Box>
        <TextField
          label="Content text"
          value={content.contentText}
          placeholder="We accept the following payment methods"
          onChange={(value) => {
            setState(
              update(state, {
                contents: {
                  [contentEdit]: { contentText: { $set: value } },
                },
              })
            )
          }}
          multiline={4}
          autoComplete="off"
          labelHidden
        />
        <p style={{marginTop:'0.5rem'}}>
          You can use markdown such as links.{" "}
          <Link
            external
            url="https://help.upsellplus.com/en/article/how-to-add-links-and-markdown-in-text-customizations-1xoxxi"
            target="_blank"
          >
            Learn more
          </Link>
        </p>
      </div>
      <CardDivider/>
      <div>
        <Box paddingBlockEnd="400">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Alignment</Text>
        </Box>
        <ButtonGroup variant="segmented">
          <Button
            pressed={"start" === content.alignment}
            onClick={() => {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { alignment: { $set: "start" } },
                  },
                })
              )
            }}
          >
            <Icon source={TextAlignLeftIcon} tone="base" />
          </Button>
          <Button
            pressed={"center" === content.alignment}
            onClick={() => {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { alignment: { $set: "center" } },
                  },
                })
              )
            }}
          >
            <Icon source={TextAlignCenterIcon} tone="base" />
          </Button>
          <Button
            pressed={"end" === content.alignment}
            onClick={() => {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { alignment: { $set: "end" } },
                  },
                })
              )
            }}
          >
            <Icon source={TextAlignRightIcon} tone="base" />
          </Button>
        </ButtonGroup>
      </div>
      <CardDivider/>
      <div>
        <Box paddingBlockEnd="400">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Size</Text>
        </Box>
        <TextSizeWrapper>
          <ButtonGroup variant="segmented">
            <div className="extraLarge-size">
              <Button
                pressed={"extraLarge" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "extraLarge" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
            <div className="large-size">
              <Button
                pressed={"large" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "large" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
            <div className="medium-size">
              <Button
                pressed={"medium" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "medium" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
            <div className="base-size">
              <Button
                pressed={"base" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "base" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
            <div className="small-size">
              <Button
                pressed={"small" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "small" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
            <div className="extraSmall-size">
              <Button
                pressed={"extraSmall" === content.size}
                onClick={() => {
                  setState(
                    update(state, {
                      contents: {
                        [contentEdit]: { size: { $set: "extraSmall" } },
                      },
                    })
                  )
                }}
              >
                <Icon source={TextFontIcon} tone="base" />
              </Button>
            </div>
          </ButtonGroup>
        </TextSizeWrapper>
      </div>
      <CardDivider/>
      <div>
        <Box paddingBlockEnd="200">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Color</Text>
        </Box>
        <ColorList>
          <div className="color-list">
            <ChoiceList
              choices={colors}
              selected={content.color}
              onChange={(value) => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { color: { $set: value[0] } },
                    },
                  })
                )
              }}
            />           
          </div>
        </ColorList>
      </div>
      <CardDivider/>
      <div>
        <Box paddingBlockEnd="200">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Icons</Text>
        </Box>
        <Box paddingBlockEnd="100">
          <Text variant="bodyMd" as="span">Popular icons</Text>
        </Box>
        <CustomChoiceList>
          <ChoiceList
            choices={PAYMENT_ICONS.map(renderChoice)}
            selected={selectedMethods}
            onChange={handleChoiceListChange}
          />
        </CustomChoiceList>
      </div>
      <CardDivider/>
      <div>
        <Box paddingBlockEnd="200">
          <Text variant="bodyMd" as="span" fontWeight="semibold">Selected Payment Methods</Text>
        </Box>
        
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="payment-methods">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {selectedMethods.map((method, index) => (
                  <Draggable 
                    key={method.value} 
                    draggableId={method.value} 
                    index={index}
                  >
                    {(provided) => (
                      <DraggableItemWrapper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div 
                          className="drag-handle"
                          {...provided.dragHandleProps}
                        >
                          <Icon source={DragHandleIcon} />
                        </div>
                        <div className="payment-info">
                          <img
                            src={method.iconUrl}
                            alt={`${method.label} icon`}
                            className="payment-icon"
                          />
                          <span className="payment-name">{method.label}</span>
                        </div>
                        <div className="actions">
                          <Button
                            className="action-button"
                            onClick={() => handleEdit(index)}
                            plain
                            icon={EditIcon}
                          />
                          <Button
                            className="action-button"
                            onClick={() => handleDelete(index)}
                            plain
                            icon={DeleteIcon}
                          />
                        </div>
                      </DraggableItemWrapper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Popover
          active={isEditModalOpen}
          activator={<div />}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingIndex(null);
          }}
        >
          <ActionList
            items={actionListItems}
          />
        </Popover>
        <Box paddingBlockStart="400">
          <Popover
            active={isAddingMore}
            activator={
              <Button
                onClick={() => setIsAddingMore(true)}
                icon={PlusIcon}
              >
                Add more icons
              </Button>
            }
            onClose={() => setIsAddingMore(false)}
          >
            <ActionList
              items={actionListItems}             
            />
          </Popover>
        </Box>
      </div>
    </FormLayout>
  );
}
