import axios from "axios"
import { navigate } from "gatsby"
import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';

export const postAuth = (shop, host, plan=null) => {
  return axios.post(`/auth`, {
    shop,host,plan
  })
}


export const getAuthData = (urlParamString) => {
  const queryParams = urlParamString || window.location.search
  const urlParams = new URLSearchParams(queryParams)
  return {
    shop: urlParams.get('shop'),
    token: urlParams.get('token'),
    expiresAt: urlParams.get('expires_at'),
    uid: urlParams.get('uid'),
    host: urlParams.get('host'),
    user_id: urlParams.get('user_id'),
  }
}
export const signin = async (shop, host, id_token) => {
    const result =  await axios({
          method: 'get',
          url: `/signin`,
          headers: {
            'X-Shopify-Shop-IdToken': id_token,
          }
      })
    if(result.data && result.data.uid) {
        return { uid: result.data.uid, token:result.data.token, user_id: result.data.user_id }
    }else{
        if(result.data && result.data.redirectUrl){
            if (window.top === window.self) {
                window.top.location.href = result.data.redirectUrl
            } else {
                const app = createApp({
                    apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY,
                    host: host
                    });
                const redirect = Redirect.create(app);
                redirect.dispatch(Redirect.Action.REMOTE, result.data.redirectUrl); 
            }
        }else{
            return await refreshAuth(shop, host)
        }
    }
}
export const isAuthenticated = async (urlParamString) => {

    const {
      shop,
      token,
      expiresAt,
      uid,
      host,
    } = getAuthData(urlParamString)
    const isValid = isAuthValid({ token, expiresAt, uid })

    console.log('Is auth valid?', isValid, !!isValid)

    if (isValid) {
        return true
    } else {
        const shopifyDomain = shop || getShopDomain()

        if (!shopifyDomain) {
            navigate('/install')
            return false
            //throw "No Shop domain"
        }

        return await refreshAuth(shopifyDomain, host)
    }

    return false
}

export const getShopDomain = () => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    let parentDomain = document.location.ancestorOrigins && document.location.ancestorOrigins[0] // if chrome iframe
    const shopDomain = urlParams.get('shop')

    if (typeof parentDomain !== 'undefined') {
        parentDomain = parentDomain.replace('https://', '')
    }

    return shopDomain || parentDomain
}

export const getShopToken = (domain) => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const token = urlParams.get('token')
    if (token) {
      return token
    }
}

const isAuthValid = ({ token, expiresAt, uid }) => {
    const shopDomain = getShopDomain()

   if (token && expiresAt && uid) {
        return { shopDomain }
    }
    return false
}



export const refreshAuth = (shopDomain, host) => {
    const shop = shopDomain || getShopDomain()

    if (!shop) {
        return false
    }
    return postAuth(shop, host)
        .then(response => {
            if (response.data && response.data.body) {
                const redirectUrl = response.data.body

                if (window.top === window.self) {
                    window.top.location.href = redirectUrl
                } else {
                    const app = createApp({
                        apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY,
                        host: host
                        });
                    const redirect = Redirect.create(app);
                    redirect.dispatch(Redirect.Action.REMOTE, redirectUrl); 
                }
            } else {
                throw new Error("Invalid /auth API response")
            }
        })
        .catch(error => {
            console.warn(error)
            return false
        })
}




