import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../../../providers/firebase'
import { navigate } from "gatsby"
import equal from "deep-equal"
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import getSymbolFromCurrency from 'currency-symbol-map';
import AddressBlockerForm from './addressBlockerForm';
import createCustomization from '../../../../helpers/createCustomization';
import DocStatus from '../../../../components/docStatus';
import { updateCustomization, deleteCustomization } from '../../../../helpers/updateCustomization';
function CreateAddressBlocker(props) {
    const { token, shop, location, firebase, host } = props
    const existingAddressBlocker = (location && location.state && location.state.addressBlocker) || null
    const from = location && location.state && location.state.from
    const isEditing = !!existingAddressBlocker
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingAddressBlocker && existingAddressBlocker.id || null, // always null if not created
      enabled: existingAddressBlocker && existingAddressBlocker.enabled || false,
      rules: existingAddressBlocker && existingAddressBlocker.rules || [],
      type: "address blocker",
    }
    const [state, setState] = useState(initialEditingState)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
      );
      const [isSubmitting, setIsSubmitting] = useState(false)
      const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
      const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
      const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
      let currentState = Object.assign({},state);
      let initialStateForCheck = Object.assign({},initialEditingState);
      delete currentState.enabled;
      delete initialStateForCheck.enabled;
      delete currentState.updatedAt;
      delete initialStateForCheck.updatedAt;
      const hasUnsavedChanges = (isCreating || isEditing) && !equal(currentState, initialStateForCheck)
      const handleFormSubmit = async (type) => {
        if (isSubmitting) {
          return
        }
        setIsSubmitting(true)
        if (isEditing) {
          await updateCustomization(state, shop, firebase)
          navigate(
            `/app/customizations/addressblocker/createAddressBlocker`,
            {
              state: {addressBlocker: {...state, updatedAt: new Date().toISOString()}, from: "Changes saved",},
              replace: true,
            }
          )
        }else{
          console.log("saving")
          const result = await createCustomization({...state, enabled: "save" === type ? false : true}, token, shop, host)
          console.log("result", result.data)
          setTimeout(() => {
            navigate(
              `/app/customizations/customizationPreview`,
              {
                state: {
                  backLink:"/app/customizations", extensionName:"Adress blocker"
                },
                replace: true,
              }
            )
          }, 1000)
        }
        setIsSubmitting(false)
      }
      const handleCustomContentDelete = async () => {
        await deleteCustomization(state.id, shop, firebase);
    
        navigate('/app/customizations', {
          state: location.state,
          replace: true,
        })
      }
    return (
          <DocStatus isEditing={isEditing} location={location} name="Address blocker" updatedAt={existingAddressBlocker?.updatedAt} handleFormSubmit={handleFormSubmit} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} token={token} shop={shop} firebase={firebase} state={state} setState={setState} from={from} fullWidth title="Create address validator" initialEditingState={initialEditingState}>
            <AddressBlockerForm token={token} shop={shop} location={location} firebase={firebase} host={host} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} handleFormSubmit={handleFormSubmit} hasUnsavedChanges={hasUnsavedChanges} handleCustomContentDelete={handleCustomContentDelete}/>
          </DocStatus>
    );
}

export default withFirebase(CreateAddressBlocker);
