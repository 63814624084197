import React from "react"
import { LegacyCard, Icon, LegacyStack, Text, Button } from "@shopify/polaris";
import { ChevronUpIcon, ChevronDownIcon, DeleteIcon } from "@shopify/polaris-icons";
import update from "immutability-helper"
import styled from "styled-components"
const DeleteWrapper = styled.div`
  cursor: pointer;
`

function ProductActions(props) {
  const {
    product,
    products,
    offerIndex,
    productIndex,
    state,
    setState,
    productEdit,
    setProductEdit,
  } = props
  const deleteOneProduct = (index) => {
    setState(
      update(state, {
        product: { [offerIndex]: { products: { $splice: [[index, 1]] } } },
      })
    )
    if (index < productEdit[1]) {
      setProductEdit([offerIndex, productEdit - 1])
    }
  }

  const moveItem = (from, to) => {
    const data = products
    const f = data.splice(from, 1)[0]
    data.splice(to, 0, f)
    setState(
      update(state, {
        product: { [offerIndex]: { products: { $set: [...data] } } },
      })
    )
  }
  const moveUp = (index) => {
    moveItem(index, index - 1)
  }
  const moveDown = (index) => {
    moveItem(index, index + 1)
  }
  return (
    <LegacyCard sectioned key={productIndex}>
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <LegacyStack alignment="center">
            <img
              className="upsellplus-image"
              src={
                (product.featuredImage && product.featuredImage) ||
                "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b"
              }
              style={{ width: "20px", height: "20px" }}
            />
            <Text variant="headingSm" as="h3">product{productIndex + 1}:{" "}
              {product.product
                ? product.product.title
                : "please choose product"}
            </Text>
          </LegacyStack>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <LegacyStack alignment="center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid #C9CCCF",
                  borderRadius: "5px 0 0 5px",
                  borderRight: "none",
                  padding: "3px 1px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (productIndex > 0) {
                    moveUp(productIndex)
                  }
                }}
              >
                {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
                <Icon
                  source={ChevronUpIcon}
                  tone={productIndex > 0 ? "base" : "subdued"}
                />
              </div>
              <div
                style={{
                  border: "1px solid #C9CCCF",
                  borderRadius: "0 5px 5px 0",
                  padding: "3px 1px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (productIndex < products.length - 1) {
                    moveDown(productIndex)
                  }
                }}
              >
                <Icon
                  source={ChevronDownIcon}
                  tone={
                    productIndex < products.length - 1 ? "base" : "subdued"
                  }
                />
              </div>
            </div>
            <Button variant="plain" onClick={() => {
                setProductEdit([offerIndex,productIndex])
              }}>
                Edit
            </Button>
            <DeleteWrapper onClick={() => deleteOneProduct(productIndex)}>
              <Button icon={DeleteIcon} tone="critical"></Button>
            </DeleteWrapper>
          </LegacyStack>
        </LegacyStack.Item>
      </LegacyStack>
    </LegacyCard>
  );
}

export default ProductActions
