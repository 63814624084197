import React, {useState,useEffect, useCallback} from 'react'  
import {
  Card,
  Tabs,
  LegacyCard
} from "@shopify/polaris"
import ProductDesktopPreview from './productDesktopPreview'
import CardDivider from '../../../../components/cardDivider';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const tabs =
[
  {
    id: 'desktop-preview',
    content: 'Desktop preview',
    panelID: 'desktop-preview-content',
  },
  {
    id: 'mobile-preview',
    content: 'Mobile preview',
    panelID: 'mobile-preview-content',
  }
];
  
function ProductPreview(props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0) 
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex),
    [],
  ) 

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
      <LegacyCard>  
        <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange} fitted>
        <CardDivider/>
        <LegacyCard.Section>
        <div style={windowDimensions?.width < 795 ? {}:{
                      zoom: 0.645,
                    }}>
            <div style={{padding: '30px 15px', background: 'black'}}>
              <div style={{background: 'white', borderRadius:'0.75rem', padding: '10px'}}>
            { selectedTabIndex === 0 &&
            <ProductDesktopPreview {...props}/>
            }
            { selectedTabIndex === 1 &&
            <ProductDesktopPreview {...props}/>
            }
            </div>
          </div>
        </div>
        </LegacyCard.Section>
        </Tabs>
      </LegacyCard>
  )
}

export default ProductPreview;