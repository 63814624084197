import React, { useCallback } from 'react'  
import { navigate } from "gatsby"
import { Frame, Page, Layout, InlineGrid, Box } from "@shopify/polaris";
import { LayoutPopupIcon, LayoutSidebarRightIcon, LayoutSectionIcon } from "@shopify/polaris-icons";
import styled from 'styled-components'
import OfferSelectionCard from '../../../components/offerSelectionCard';
const OfferTypeWrapper = styled.div`
    .offer-type-container {
      padding: 25px 150px 40px;
      .nickname-container {
        text-align: center;
        max-width: 430px;
        margin: auto;
        margin-bottom: 35px;
        .nickname-title {
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
        }
      }
      .help-button {
        text-align: center;
        margin-top: 30px;
      }
      .offer-type {
        
        .offer-type-title {
            text-align: center;
            margin-bottom: 25px;
            h3 {
                color: #6D7175;
            }
        }
        .offer-type-option {
            height: 150px;
            width: 150px;
            padding: 20px 20px 5px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            cursor: pointer;
            gap: 20px;
            .offer-type-option-container {
                display: flex;
                flex-direction: row;
                gap: 5px;
                justify-content: center;
                text-align: center;
                .Polaris-Icon {
                    margin-left: -27px;
                    margin-top: 0;
                }
                span {
                    color: #000;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                }
            }
            .offer-enabled {
                svg {
                    fill: #007F5F;
                }
            }
            .offer-type-edit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                button {
                    background: #95C9B4;
                    border-color: #202223;
                }
                .Polaris-Icon {
                    width: 50px;
                    height: 50px;
                }
            }
            .upsell-badge {
                position: absolute;
                bottom: 10px;
                .Polaris-Badge {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                }
            }
            .upsell-beta {
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%)
            }
        }
        .active {
            .Polaris-Card {
                background: #008060;
            }
            .offer-type-option-container {
                span {
                    color:#fff;
                }
            }
            svg {
                fill: #fff;
            }
        }

      }
      .errors {
          padding: 5px 0;
      }
    }
`

function CreateCartType(props) {
    const { location } = props
    const rules = location && location.state && location.state.rules

    const createCartPageHandler = useCallback(() => {
        navigate("/app/offers/createCart", {
          state: { rules: rules },
          replace: true,
        })
      }, [rules])
      const createCartDrawerHandler = useCallback(() => {
        navigate("/app/offers/createCartDrawer", {
          state: { rules: rules },
          replace: true,
        })
      }, [rules])
      const createCartPopupHandler = useCallback(() => {
        navigate("/app/offers/cart/v1/createCart", {
          state: { rules: rules },
          replace: true,
        })
      }, [rules])
    

    const CART_OFFER_SELECTION = [
        {
          title: "Popup Upsell",
          type: "Cart upsell",
          content: "This will pop up on the bottom right of the page",
          icon: LayoutPopupIcon,
          url: createCartPopupHandler,
        },
        {
          title: "Cart Page Upsell",
          type: "Cart upsell",
          content: "Show a ‘You may also like’ section on your main cart page",
          icon: LayoutSectionIcon,
          url: createCartPageHandler,
        },
        {
          title: "Cart Drawer Upsell",
          type: "Cart upsell",
          content: "This will show in your side cart drawer or slide cart",
          icon: LayoutSidebarRightIcon,
          url: createCartDrawerHandler,
        },
      ]
    return (
        <Frame>
        <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate("/app/offers/createOfferType")
                }}} 
                title="Select Cart Upsell type">
        <Layout>
            <Layout.Section>
                <Box paddingBlockEnd="800">
                <OfferTypeWrapper>
                <InlineGrid
                              gap="400"
                              columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                            >
                                {
                                   CART_OFFER_SELECTION.map((item, index) => {
                                    return <OfferSelectionCard key={index} {...item} />
                                    })
                                }
                            </InlineGrid>
                    
                </OfferTypeWrapper>
                </Box>
            </Layout.Section>
        </Layout>
    </Page>
   </Frame>
    );
}

export default CreateCartType;