import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useCallback,
} from "react"
import FirebaseContext from "../../../providers/firebase"
import { v4 as uuidv4 } from "uuid"
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { collection, doc } from 'firebase/firestore';
import gql from "graphql-tag"
import { useLazyQuery } from "react-apollo"
import { navigate } from "gatsby"
import {
  LegacyCard,
  Page,
  IndexTable,
  Thumbnail,
  Badge,
  useIndexResourceState,
  LegacyStack,
  Popover,
  ActionList,
  Spinner,
  Button,
  Layout,
  Toast,
  Frame,
  EmptyState,
  Box,
} from "@shopify/polaris"
import {
  DisabledIcon,
  ImageIcon,
  FormsIcon,
  MenuVerticalIcon,
  DeleteIcon,
  EditIcon,
  DuplicateIcon,
  PlayIcon,
  ImageWithTextOverlayIcon,
  CreditCardIcon,
  DeliveryIcon,
} from "@shopify/polaris-icons";
import {
  updateCustomization,
  deleteCustomization,
} from "../../../helpers/updateCustomization"
import createCustomization from "../../../helpers/createCustomization"
import ClipboardMinor from "../../../images/ClipboardMinor.png"
import styled from "styled-components"
const FrameWrapper = styled.div`
.Polaris-Frame {
  max-height: 0;
  min-height: 0;
}
`
const GET_CUSTOMIZATIONS = gql`
  {
    deliveryCustomizations(first: 25) {
      nodes {
        functionId
        id
        enabled
        title
        metafield(
          namespace: "upsellplus:delivery-customization"
          key: "function-configuration"
        ) {
          value
        }
      }
    }
    paymentCustomizations(first: 25) {
      nodes {
        functionId
        id
        enabled
        title
        metafield(
          namespace: "upsellplus:payment-customization"
          key: "function-configuration"
        ) {
          value
        }
      }
    }
  }
`
function List(props) {
  const { firebase, shop, token, host } = useContext(FirebaseContext)
  const customizationsCollection = collection(firebase.firestore, 'shops', shop, 'customizations');
  const [customizations, customizationsLoading, customizationsError] = useCollection(customizationsCollection);
  const [getCustomizaton, { loading, error, data }] = useLazyQuery(
    GET_CUSTOMIZATIONS,
    {
      fetchPolicy: "no-cache",
    }
  )

  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(shopDoc, {
    transform: (snap) => snap.shopData
  });
  const domain = shopData && shopData.domain
  const [popoverActive, setPopoverActive] = useState(null)
  const [toastActive, setToastActive] = useState({active: false, message: ""})
  const [isLoading, setIsLoading] = useState(-1)
  useEffect(() => {
    getCustomizaton()
  }, [])

  const handleGoToCheckout = useCallback(() => {
    window.open(`https://${domain}/admin/settings/checkout/editor`, "_blank")
  }, [domain])

  const togglePopoverActive = (id) => {
    popoverActive === id ? setPopoverActive(null) : setPopoverActive(id)
  }
  const toggleCustomField = async (id, enabled) => {
    setIsLoading(id)
    await updateCustomization(
      {
        id: id,
        enabled: !enabled,
      },
      shop,
      firebase
    )
    togglePopoverActive(id)
    setIsLoading(-1)
    setToastActive({active: true, message: enabled ? "Customization disabled" : "Customization enabled"})
  }
  const deleteCustomField = async (id) => {
    setIsLoading(id)
    await deleteCustomization(id, shop, firebase)
    togglePopoverActive(id)
    setIsLoading(-1)
    setToastActive({active: true, message: "Customization deleted"})
  }

  const duplicateCustomField = async (id) => {
    setIsLoading(id)
    const customization = customizations.docs
      .map((customization) => customization.data())
      .find((customField) => customField.id === id)
    let copyCustomization = {
      ...customization,
      id: uuidv4(),
      name: `Copy of ${customization.name}`,
    }
    const response = await createCustomization(
      copyCustomization,
      token,
      shop,
      host
    )
    if (response?.status === 200) {
      togglePopoverActive(id)
      setIsLoading(-1)
      setToastActive({active: true, message: "Customization duplicated"})
    }
  }

  const toggleShopifyfunctions = async (id, functionId, enabled, type) => {
    setIsLoading(id)
    const query =
      type === "payment customization"
        ? {
            query: `mutation PaymentCustomizationUpdate($id: ID!, $input: PaymentCustomizationInput!) {
              paymentCustomizationUpdate(id: $id, paymentCustomization: $input) {
                paymentCustomization {
                  id
                  enabled
                }
                userErrors {
                  message
                }
              }
            }`,
            variables: {
              input: {
                functionId: functionId,
                enabled: !enabled,
              },
              id: `gid://shopify/PaymentCustomization/${id}`,
            },
          }
        : {
            query: `mutation deliveryCustomizationUpdate($id: ID!, $input: DeliveryCustomizationInput!) {
              deliveryCustomizationUpdate(id: $id, deliveryCustomization: $input) {
                deliveryCustomization {
                  id
                  enabled
                }
                userErrors {
                  message
                }
              }
            }`,
            variables: {
              input: {
                functionId: functionId,
                enabled: !enabled,
              },
              id: `gid://shopify/DeliveryCustomization/${id}`,
            },
          }
    const response = await fetch("/api/graphql", {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
        "x-shopify-shop-domain": shop,
        "x-shopify-access-token": token,
      },
    })
    if (response.ok) {
      getCustomizaton()
      togglePopoverActive(id)
      setIsLoading(-1)
      setToastActive({active: true, message: enabled ? "Customization disabled" : "Customization enabled"})
    }
  }

  const deleteShopifyfunctions = async (id, type) => {
    setIsLoading(id)
    const query =
      type === "payment customization"
        ? {
            query: `mutation paymentCustomizationDelete($id: ID!) {
        paymentCustomizationDelete(id: $id) {
          deletedId
          userErrors {
            field
            message
          }
        }
      }`,
            variables: {
              id: `gid://shopify/PaymentCustomization/${id}`,
            },
          }
        : {
            query: `mutation deliveryCustomizationDelete($id: ID!) {
              deliveryCustomizationDelete(id: $id) {
          deletedId
          userErrors {
            field
            message
          }
        }
      }`,
            variables: {
              id: `gid://shopify/DeliveryCustomization/${id}`,
            },
          }
    const response = await fetch("/api/graphql", {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
        "x-shopify-shop-domain": shop,
        "x-shopify-access-token": token,
      },
    })

    if (response.ok) {
      getCustomizaton()
      togglePopoverActive(id)
      setIsLoading(-1)
      setToastActive({active: true, message: "Customization deleted"})
    }
  }

  const copyIdToClipboard = (e, id) => {
    e.stopPropagation()
    navigator.clipboard
      .writeText(id)
      .then(() => setToastActive({active: true, message: "ID copied"}))
      .catch((err) => console.log("Error in copy customization id: ", err))
  }
  const toggleActive = useCallback(() => setToastActive((prevState) => ({...prevState, active: !prevState.active})), []);
  const toastMarkup = toastActive.active ? (
    <Toast
      content={toastActive.message}
      onDismiss={toggleActive}
      duration={1500}
    />
  ) : null
  const thumbnailMarkup = (type) => {
    switch (type) {
      case "custom content":
        return <Thumbnail size="small" source={ImageWithTextOverlayIcon} />;
      case "address blocker":
        return <Thumbnail size="small" source={DisabledIcon} />;
      case "payment customization":
        return <Thumbnail size="small" source={CreditCardIcon} />;
      case "delivery customization":
        return <Thumbnail size="small" source={DeliveryIcon} />;
      default:
        return <Thumbnail size="small" source={FormsIcon} />;
    }
  }
  const customizationUrl = (type, id, functionId) => {
    switch (type) {
      case "custom content":
        return `/customcontent/${id}`
      case "address blocker":
        return "/address-blocker"
      case "payment customization":
        return `/app/customizations/hidepaymentmethods/hidePaymentMethodsItem`
      case "delivery customization":
        return `/app/customizations/hidedeliverymethods/hideDeliveryMethodsItem`
      default:
        return `/customfield/${id}`
    }
  }
  const navigateToCustomization = async (id, type, customization) => {
    console.log("id", id)
    if ("address blocker" === type) {
      await navigate(`/app/customizations/addressblocker/addressBlockerItem`, {
        state: {
          addressBlocker: customization,
        },
      })
    } else {
      if (
        "payment customization" === type ||
        "delivery customization" === type
      ) {
        await navigate(customizationUrl(type, id, customization.functionId), {
          state: {
            customization: {
              ...JSON.parse(customization.metafield.value),
              id: customization.id,
              enabled: customization.enabled,
            },
          },
        })
      } else {
        if ("custom content" === type) {
          await navigate(`/app/customizations/customcontent/customContentItem`, {
            state: {
              customContent: customization,
            },
          })
        } else {
          navigate(`/app/customizations/customfield/customFieldItem`, {
            state: {
              customField: customization,
            },
          })
        }
      }
    }
  }
  const actionListMarkup = (id, type, functionId, enabled, customization) => {
    if ("address blocker" === type) {
      return [
        {
          items: [
            {
              content: enabled ? "Disable" : "Enable",
              icon: enabled ? DisabledIcon : PlayIcon,
              onAction: () => {
                toggleCustomField(id, enabled)
              },
            },
            {
              content: "Edit",
              icon: EditIcon,
              onAction: () => {
                navigate(`/app/customizations/addressblocker/addressBlockerItem`, {
                  state: {
                    addressBlocker: customization,
                  },
                })
              },
            },

            {
              destructive: true,
              content: "Delete",
              icon: DeleteIcon,
              onAction: () => {
                deleteCustomField(id)
              },
            },
          ],
        },
      ];
    } else {
      if (
        "payment customization" === type ||
        "delivery customization" === type
      ) {
        return [
          {
            items: [
              {
                content: enabled ? "Disable" : "Enable",
                icon: enabled ? DisabledIcon : PlayIcon,
                onAction: () => {
                  toggleShopifyfunctions(id, functionId, enabled, type)
                },
              },
              {
                content: "Edit",
                icon: EditIcon,
                onAction: async () => {
                  await navigate(
                    customizationUrl(type, id, customization.functionId),
                    {
                      state: {
                        customization: {
                          ...JSON.parse(customization.metafield.value),
                          id: customization.id,
                          enabled: customization.enabled,
                        },
                      },
                    }
                  )
                },
              },

              {
                destructive: true,
                content: "Delete",
                icon: DeleteIcon,
                onAction: () => {
                  deleteShopifyfunctions(id, type)
                },
              },
            ],
          },
        ];
      } else {
        return [
          {
            items: [
              {
                content: enabled ? "Disable" : "Enable",
                icon: enabled ? DisabledIcon : PlayIcon,
                onAction: () => {
                  console.log("id", id, enabled)
                  toggleCustomField(id, enabled)
                },
              },
              {
                content: "Edit",
                icon: EditIcon,
                onAction: () => {
                  if ("custom content" === type) {
                    navigate(`/app/customizations/customcontent/customContentItem`, {
                      state: {
                        customContent: customization,
                      },
                    })
                  } else {
                    navigate(`/app/customizations/customfield/customFieldItem`, {
                      state: {
                        customField: customization,
                      },
                    })
                  }
                },
              },
              {
                content: "Duplicate",
                icon: DuplicateIcon,
                onAction: () => {
                  duplicateCustomField(id)
                },
              },
              {
                destructive: true,
                content: "Delete",
                icon: DeleteIcon,
                onAction: () => {
                  deleteCustomField(id)
                },
              },
            ],
          },
        ];
      }
    }
  }
  const resourceName = {
    singular: "Customization",
    plural: "Customizations",
  }
  let tableData = []
  if (customizations) {
    tableData = tableData.concat(
      customizations.docs.map((customization) => customization.data())
    )
  }
  if (data) {
    data.deliveryCustomizations.nodes.map((item) => {
      if (
        !tableData.find(
          (customField) =>
            customField.id ===
            item.id.replace("gid://shopify/DeliveryCustomization/", "")
        )
      ) {
        tableData.push({
          ...item,
          type: "delivery customization",
          name: item.title,
          id: item.id.replace("gid://shopify/DeliveryCustomization/", ""),
        })
      }
    })
    data.paymentCustomizations.nodes.map((item) => {
      if (
        !tableData.find(
          (customField) =>
            customField.id ===
            item.id.replace("gid://shopify/PaymentCustomization/", "")
        )
      ) {
        tableData.push({
          ...item,
          type: "payment customization",
          name: item.title,
          id: item.id.replace("gid://shopify/PaymentCustomization/", ""),
        })
      }
    })
  }
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(tableData)
  const rowMarkup = tableData?.map(
    ({ id, name, enabled, type, functionId }, index) => {
      /* The form layout, created using Polaris components. Includes the custom field data set above. */
      return (
        <IndexTable.Row
          id={id}
          key={id}
          position={index}
          tone={!enabled && "subdued"}
          selected={selectedResources.includes(id)}
          onNavigation={async (id) => {
            await navigateToCustomization(id, type, tableData[index])
          }}
        >
          <IndexTable.Cell>
            <LegacyStack wrap={false} align="start" blockAlign="center" gap="2">
              {thumbnailMarkup(type)}
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={"#"}
                data-primary-link
              >
                <p>{"address blocker" === type ? "Address blocker" : name}</p>
                <p>
                  {type
                    ? type.charAt(0).toUpperCase() + type.slice(1)
                    : "Custom field"}
                </p>
              </a>
            </LegacyStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {enabled ? (
              <Badge tone="success">Enabled</Badge>
            ) : (
              <Badge>Disabled</Badge>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {type
              ? type.charAt(0).toUpperCase() + type.slice(1)
              : "Custom field"}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {"address blocker" === type ? null : (
              <LegacyStack wrap={false} align="start" blockAlign="center" gap="2">
                <span>{id}</span>
                <Button  onClick={(e) => copyIdToClipboard(e, id)} variant="plain">
                  <img src={ClipboardMinor} alt={"ClipboardMinor"} />
                </Button>
              </LegacyStack>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {id === isLoading ? (
              <LegacyStack align="center">
                <Spinner accessibilityLabel="Loading" size="small" />
              </LegacyStack>
            ) : (
              <div onClick={(e) => e.stopPropagation()}>
                <Popover
                  active={popoverActive === id}
                  activator={
                    <Button
                      icon={MenuVerticalIcon}
                      onClick={() => togglePopoverActive(id)}

                      ariaHaspopup
                      variant="plain" />
                  }
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                >
                  <ActionList
                    actionRole="menuitem"
                    sections={actionListMarkup(
                      id,
                      type,
                      functionId,
                      enabled,
                      tableData[index]
                    )}
                  />
                </Popover>
              </div>
            )}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  )
  return (
    tableData?.length > 0 || customizationsLoading ? 
    <Page
    fullWidth
    title="Customizations"
    primaryAction={<Button

      onClick={() => {
        navigate(
          "/app/customizations/createCustomizations",
          {
            replace: true,
          }
        )
      }}
      variant="primary">
      New customization
    </Button>}
    secondaryActions={<Button onClick={handleGoToCheckout}>To Checkout Editor</Button>}
    >
        <Layout>
          <Layout.Section>
          <Box paddingBlockEnd="800">
            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={tableData?.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Customization" },
                  { title: "Status" },
                  { title: "Type" },
                  { title: "Id" },
                  { title: "" },
                ]}
                selectable={false}
                loading={customizationsLoading}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
            </Box>
          </Layout.Section>
        </Layout>
      <FrameWrapper>
        <Frame>{toastMarkup}</Frame>
      </FrameWrapper>
    </Page>
    :
    <Page title="Customizations">
      <Box paddingBlockEnd="800">
      <LegacyCard sectioned>
        <EmptyState
          heading="Create customization"
          action={{onAction: () => {
            navigate(
              "/app/customizations/createCustomizations",
              {
                replace: true,
              }
            )
          }, content: 'New customization'}}
          secondaryAction={{
            content: 'To Checkout Editor',
            onAction: () => handleGoToCheckout()
          }}
          image="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/customization-empty-status.png?alt=media&token=2738b7a1-c75e-4524-833a-df8906f15248"
          fullWidth
        >
          <p>Customize your checkout</p>
        </EmptyState>
      </LegacyCard>
      </Box>
    </Page>
  );
}
export default List
