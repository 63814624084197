import React, {
    Fragment,
    useState,
    useEffect,
  } from 'react'
  import update from 'immutability-helper'
  import equal from 'deep-equal'
  import { Layout, Button, ButtonGroup, LegacyCard, Form, Page, Text, Tabs, } from "@shopify/polaris";
  import { withFirebase } from '../../../providers/firebase'
  import styled from 'styled-components'
  import { navigate } from "gatsby"
  import PostPurchasePreview from './postpurchase/postPurchasePreview'
  import './create.css'
  import Constants from '../../../helpers/constants'
  import createRule from '../../../helpers/createRule'
  import { updateRule, deleteRule } from '../../../helpers/updateRule'
  import SmartRules from './smartRules'
  import ProductTab from './postpurchase/productTab';
  import { doc } from 'firebase/firestore';
  import { useDocumentData } from 'react-firebase-hooks/firestore';
  import getSymbolFromCurrency from 'currency-symbol-map'
  import CreateOfferSteps from './createOfferSteps';
  import ShopBrain from './common/instructions/shopBrain/shopBrain';
  import PageHeader from '../../../components/pageHeader';
  import './common.css';
  import CardDivider from '../../../components/cardDivider';
  import CardWrapper from '../../../components/cardWrapper';
  // Source: https://stackoverflow.com/a/44134328
  
  const PostPurchaseWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `
  function Create(props) {
    const { token, shop, location, firebase, host } = props
    const existingOffer = location && location.state && location.state.rule
    const rules = location && location.state && location.state.rules
    const from = location && location.state && location.state.from
    const isEditing = !!existingOffer
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingOffer && existingOffer.id || null, // always null if not created
      offerType: existingOffer && existingOffer.offerType || 'Post Purchase',
      name: existingOffer && existingOffer.name || '',
      offerStarting: existingOffer && existingOffer.offerStarting || '',
      offerEnding: existingOffer && existingOffer.offerEnding || '',
      product: existingOffer && existingOffer.product || [
        { 
          expiration: 15,
          products: [
            {
              discount: { sym: '$', value: 0 }
            }
          ],
          productsPerRow: 1,
        }
      ],
      discount: existingOffer && existingOffer.discount || {sym:'$',value:0},
      enabled: existingOffer && existingOffer.enabled || false,
      triggers: existingOffer && existingOffer.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
              }
          }
      },
      shopBrain: existingOffer ? existingOffer?.shopBrain ? existingOffer?.shopBrain?.inclusion ? existingOffer?.shopBrain : {inclusion:[{setting: 'tags',value: null}],exclusion: [{setting: 'tags',value: null}]} : null : null,
      aiConditions: existingOffer ? existingOffer.aiConditions : {
        customerTags: [],
        products: []
      },
      variantsTriggers: existingOffer && existingOffer.variantsTriggers || {
        if:{
          all:[
            {fact:'cartProducts',operator: 'hasAny',value: null},
            {fact:'cartProducts',operator: 'Variant', value: null}
          ]
        },
        then: {
          all:[
            {fact:'upsellVariant',operator: 'Variant',value: null}
          ]
        },
        swap: false,
        type: 'manual'
      },
      isExcludeProduct: existingOffer?.isExcludeProduct ? existingOffer.isExcludeProduct : false,
    }
    const [state, setState] = useState(initialEditingState)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [productEdit, setProductEdit] = useState(
      isCreating ? [0, 0] : [-1, -1]
    )
    const [error, setError] = useState(null)
    const [offerType, setOfferType] = useState(() => {
      if (state?.variantsTriggers?.type) {
        return state?.variantsTriggers?.type;
      } else {
        return state.shopBrain ? 'manual' : 'ai';
      }
    });
    const [shopBrainType, setShopBrainType] = useState(() => {
      if (state?.variantsTriggers?.shopBrainType) {
        return state?.variantsTriggers?.shopBrainType;
      } else {
        return 'related';
      }
    });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc,
      {
        transform: (snap) => snap.shopData
      }
    );

    let currentState = Object.assign({},state);
    let initialStateForCheck = Object.assign({},initialEditingState);
    delete currentState.enabled;
    delete initialStateForCheck.enabled;
    const hasUnsavedChanges = (isCreating || isEditing) && !equal(currentState, initialStateForCheck)

    const handleRuleDelete = async () => {
      await deleteRule(state.id, token, shop, firebase);
  
      navigate('/app/offers', {
        state: {
          ...location.state,
          tabIndex: Constants.TAB_INDEX.OFFER_LIST
        },
        replace: true,
      })
    }
    const handleFormSubmit = async (type) => {
  
      if (isSubmitting) {
        return
      }
      if(Array.isArray(state.product) && Array.isArray(state.product[0].products) && !state.product[0].products[0].product && !state?.shopBrain){
        setError({product: "Please select a product"})
        return "error"
      }else{
        if(error != null){
          setError(null)
        }
      }
      setIsSubmitting(true)
  
      try {
        if (isEditing) {
          // TODO
          console.log('update rule.')
          await updateRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
          }, token, shop, firebase);
  
          navigate(
            `/app/offers/createPostPurchase`,
            {
              state: {
                rule: {...state, updatedAt: new Date().toISOString()},
                from: "Changes saved",
              },
              replace: true,
            }
          )
        } else {
                 
          const result = await createRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            enabled: "save" === type ? false : true,
          }, token, shop, host)
          if(result && result.data && result.data.rules.filter( rule => "Post Purchase" === rule.offerType ).length > 1){
            navigate(
              `/app/offers/postPurchaseOfferItem`,
              {
                state: {
                  rule: result.data.rules.find(rule => rule.id === result.data.id),
                  from: "Offer saved",
                },
                replace: true,
              }
            )
          }else{
            setTimeout(() => {
              navigate(
                `/app/offers/offerOnboarding`,
                {
                  state: {
                    backLink:"/app/offers",
                    from: from,
                    extensionName: "Post-purchase upsell",
                    rules: rules,
                  },
                  replace: true,
                }
              )
            }, 1000)  
          }
          console.log('result from creating rule: ', result)
        }
  
      }
      catch (e) {
        console.log('Error creating rule: ', e)
      }
  
      setIsSubmitting(false)
    }
  
    const addSmartRule =  () => {
      const conditions = {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
    }
      setState(update(state, { triggers: { $set: conditions }}))
    }
 
    const locale = shopData && shopData.primary_locale || "en-US";
    const currency = (shopData && shopData.currency) ? shopData.currency : 'USD';
    const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
    return (
            <PageHeader 
              title={initialEditingState.name} 
              location={location}
              link={"/app/offers"}
              backLinkForNew={"/app/offers/createOfferType"}
              from={from}
              existingOffer={existingOffer}
              token={token} 
              shop={shop} 
              firebase={firebase} 
              state={state} 
              setState={setState} 
              hasUnsavedChanges={hasUnsavedChanges} 
              handleFormSubmit={handleFormSubmit} 
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              windowDimensions={windowDimensions}
              initialEditingState={initialEditingState}
              >
        <Layout>
          
          <Layout.Section>
          <div style={{ marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.product || 
                    !state.name ||
                    !state.title
                  }
                  onSubmit={handleFormSubmit}
            >

              <ProductTab host={host} shop={shop} token={token} shopData={shopData} state={state} setState={setState} productPickerShow={productPickerShow} error={error} setError={setError} currency={currency} currencySymbol={currencySymbol} productEdit={productEdit} setProductEdit={setProductEdit} checkoutOfferType={offerType} setCheckoutOfferType={setOfferType} existingOffer={existingOffer} shopBrainType={shopBrainType}/>
              {
                state?.shopBrain &&
                  <ShopBrain host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} checkoutOfferType={offerType} offerType={"pp"}/>
              }
              <CardWrapper title="Smart Rules" subtitle="Logical conditions for when to show this offer">
                <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"pp"}/>
              </CardWrapper>
              </Form>
            </div>
          </Layout.Section>
          <Layout.Section variant="oneThird">
          <div
            style={windowDimensions?.width < 795  ? {} :(windowDimensions?.width < 965?
              {position: 'fixed',
                maxWidth: 311.34,
                overflowY: 'auto',
                overflowX: 'hidden',
                height: "100vh",
                paddingBottom: "100px",
              }
              :{
              position: 'fixed',
              width:'100%',
              maxWidth: 311.34,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: "100vh",
              paddingBottom: "100px",
            })}>
                    <PostPurchasePreview 
                      {...state} 
                      {...props}
                      currencySymbol={currencySymbol}
                      locale={locale}
                      currency={currency}
                      productEdit={productEdit}
                      offerType={offerType}
                      windowDimensions={windowDimensions}
                    />
          </div>
          </Layout.Section>
        </Layout>
      </PageHeader>
    );
  }
  
  export default withFirebase(Create);