import React, { useState } from "react"
import {
  LegacyCard,
  FormLayout,
  TextField,
  Button,
  Layout,
  LegacyStack,
  Popover,
  Text,
  Form,
  ChoiceList,
  Select,
  InlineError,
  Box,
} from "@shopify/polaris"
import update from "immutability-helper"
import SmartRules from '../../offers/smartRules'
import CardDivider from "../../../../components/cardDivider"
const deliveryTypes = [
    {
      label: "Local delivery",
      value: "LOCAL",
    },
    {
      label: "Local pickup",
      value: "PICK_UP",
    },
    {
      label: "Shipping to a pickup point",
      value: "PICKUP_POINT",
    },
    {
      label: "Retail",
      value: "RETAIL",
    },
    {
      label: "Shipping",
      value: "SHIPPING",
    },
  ]
const HideDeliveryMethodsForm = ( props ) => {
const { token, host, shop,location, error, state, setState, productPickerShow, setProductPickerShow, currencySymbol, isCreating, isEditing, isSubmitting, hasUnsavedChanges, handleFormSubmit, handleCustomContentDelete } = props
const [isLoading, setIsLoading] = useState(false)
  const [popoverActive, setPopoverActive] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const addSmartRule =  () => {
    const conditions = {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
  }
    setState(update(state, { triggers: { $set: conditions }}))
  }
  const togglePopoverActive = () => {
    setPopoverActive((popoverActive) => !popoverActive)
    setExpanded(!expanded)
  }
  const handleInputChange = (value, name) => {
    setState(update(state, { deliveryMethodName: { $set: value } }))
  }
  const deliveryMethodsMarkup = (type) => {
    switch (true) {
      case "nameContains" === type || "nameNotContains" === type:
        return (
          <TextField
            type="text"
            label="Delivery method"
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            disabled={isLoading}
            requiredIndicator
            labelHidden
          />
        )
      case "costIs" === type ||
        "costMoreThan" === type ||
        "costLessThan" === type:
        return (
          <TextField
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            autoComplete="off"
            placeholder="0.00"
            prefix={"$"}
            type="number"
          />
        )
      case "allDeliveryMethods" === type:
        return false
      case "deliveryType" === type:
        return (
          <Popover
            active={popoverActive}
            activator={
              <Button
                onClick={togglePopoverActive}
                textAlign="left"
                disclosure={expanded ? "up" : "down"}
                fullWidth
              >
                {!Array.isArray(state?.deliveryMethodName) ||
                (Array.isArray(state?.deliveryMethodName) &&
                state?.deliveryMethodName.length === 0)
                  ? "Select delivery types"
                  : deliveryTypes
                      .filter((d) =>
                        state?.deliveryMethodName.includes(d.value)
                      )
                      .map((d) => d.label)
                      .join(", ")}
              </Button>
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
            fullWidth
            sectioned
          >
            <ChoiceList
              allowMultiple
              choices={deliveryTypes}
              selected={
                Array.isArray(state?.deliveryMethodName)
                  ? state?.deliveryMethodName
                  : []
              }
              onChange={(value) =>
                handleInputChange(value, "deliveryMethodName")
              }
            />
          </Popover>
        )
      default:
        return (
          <TextField
            type="text"
            label="Delivery method"
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            disabled={isLoading}
            requiredIndicator
            labelHidden
          />
        )
    }
  }
  return (
      <Layout>
        <Layout.Section>
          {/*isCreating && (
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          )*/}
          <div style={{ marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.name 
                  }
                  onSubmit={handleFormSubmit}>
            <FormLayout>
              <LegacyCard sectioned>
                <Text  variant="bodyMd" as="h6" fontWeight="semibold">Hide delivery method</Text>
                <CardDivider/>
                <Box paddingBlockStart="400">
                <FormLayout>
                <FormLayout.Group>
                    <LegacyStack alignment="center">
                      <LegacyStack.Item>
                        <Select
                          label="Delivery method option"
                          labelHidden
                          options={[
                            { label: "Name contains", value: "nameContains" },
                            {
                              label: "Name does not contain",
                              value: "nameNotContains",
                            },
                            {
                              label: "All delivery methods",
                              value: "allDeliveryMethods",
                            },
                            { label: "Delivery cost is", value: "costIs" },
                            {
                              label: "Delivery cost is more than",
                              value: "costMoreThan",
                            },
                            {
                              label: "Delivery cost is less than",
                              value: "costLessThan",
                            },
                            { label: "Delivery type", value: "deliveryType" },
                          ]}
                          onChange={(value) => {
                            if ("deliveryType" === value) {
                                setState(update(state, { deliveryMethodNameOption: { $set: value }, deliveryMethodName: { $set: [] } }))
                              } else {
                                setState(update(state, { deliveryMethodNameOption: { $set: value }, deliveryMethodName: { $set: "" } }))
                              }
                          }}
                          value={state?.deliveryMethodNameOption}
                        />
                      </LegacyStack.Item>
                      <LegacyStack.Item fill>
                        <LegacyStack alignment="center">
                          <LegacyStack.Item fill>
                            {deliveryMethodsMarkup(
                              state?.deliveryMethodNameOption
                            )}
                          </LegacyStack.Item>
                          {/*
                          <LegacyStack.Item>
                            <DeleteWrapper
                              onClick={() =>
                                console.log("Delete delivery method")
                              }
                            >
                              <Icon source={DeleteMajor} color="critical" />
                            </DeleteWrapper>
                          </LegacyStack.Item>
                            */}
                        </LegacyStack>
                      </LegacyStack.Item>
                    </LegacyStack>
                  </FormLayout.Group>
                </FormLayout>
                </Box>
                {
                  error && <InlineError message={error} fieldID="deliveryMethod" />
                }
              </LegacyCard>
              <LegacyCard sectioned>
                <Text  variant="bodyMd" as="h6" fontWeight="semibold">Display conditions</Text>
                <CardDivider/>
                <Box paddingBlockStart="200">
                <FormLayout>
                  <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"customization"} factsOptionsValue={[
                  { label: "Cart products", value: "cartProducts" },
                  { label: "Cart total", value: "cartTotal" },
                  { label: "Customer", value: "customer" },
                ]} customerOperatorOptionsValue={[{label: 'Has Language', value: 'hasCustomerLanguage'}]}/>
                </FormLayout>
                </Box>
              </LegacyCard>
              
            </FormLayout>
              </Form>
            </div>
        </Layout.Section>
       
      </Layout>
  )
}

export default HideDeliveryMethodsForm