import React, { useState, useContext, useCallback, useEffect } from "react"
import { navigate } from "gatsby"
import {
  Page,
  Layout,
  LegacyCard,
  Icon,
  LegacyStack,
  ButtonGroup,
  Button,
  Toast,
  Frame,
  Text,
  InlineGrid,
  Card,
  Box,
  BlockStack,
} from "@shopify/polaris";
import { ExternalIcon, ClipboardIcon } from "@shopify/polaris-icons";
import { doc } from 'firebase/firestore';
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../../providers/firebase"
import styled from "styled-components"
import OnboardingForNew from "../../components/onBoardingForNew"
import CardDivider from "../../components/cardDivider";
import getMainThemeId from "../../helpers/getMainThemeId";
const FrameWrapper = styled.div`
.Polaris-Frame {
  max-height: 0;
  min-height: 0;
}
`
const OnboardingLastStepWrapper = styled.div`
  padding: 50px 0 10px 0;
  .preview-button {
    padding: 25px 0 15px 0;
    .Polaris-Button {
      width: 485px;
    }
  }
  .instructions {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    .instructions-image {
      max-width: 575px;
      margin: 0 auto 20px;
      padding: 10px;
      border: 1px solid #aaaaaa;
      border-radius: 8px;
      background: #f8f8f8;
      div {
        border: 1px solid #aaaaaa;
        border-radius: 8px;
        img {
          width: 100%;
        }
      }
    }
  }
  .action-buttons {
    padding: 15px 0 0px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .checkout-editor-button {
      .Polaris-Button {
        border: 2px solid #008060;
      }
    }
    .add-app-block {
      color: #2e61ce;
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      svg {
        fill: #2e61ce;
      }
    }
    .save-button {
      .Polaris-Button {
        background: black;
        min-height: auto;
      }
    }
    .extension {
      border: 1px solid #ebebeb;
      padding: 8px 10px;
      border-radius: 5px;
      .extension-name {
        p:first-child {
          font-weight: bold;
          font-size: 10px;
        }
        p:nth-child(2) {
          font-size: 10px;
        }
        p:last-child {
          font-size: 8px;
          color: #727272;
        }
      }
    }
  }
`
const STEP = [
  {
    extensionName: "Checkout upsell",
    title: "Enable the Checkout upsell extension in your checkout",
    subtitle: "Select where you want to show upsell offer",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: "Add App block",
        desp: "Go to Checkout Editor and click  on ‘Add app block’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step1.png?alt=media&token=3a7d5618-f2b8-4cf7-9322-53ee96bc020c",
      },
      {
        title: "Select ‘Upsell Checkout Offers’",
        desp: "From the list find and select ‘UpsellPlus Checkout’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step2.png?alt=media&token=985be51e-5c34-4dca-8540-6a190815778d",
      },
      {
        title: "Drag & place in section",
        desp: "Select and drag ‘Upsellplus Checkout’ in any section",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step3.png?alt=media&token=354eb8d6-6bd9-447e-8c72-6c8aa23b5705",
      },
      {
        title: "Save",
        desp: "After making the changes be sure to click on ‘Save’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step4.png?alt=media&token=ffeb05ee-9af8-4eaf-9bdc-1cd5202d092c",
      },
    ],
  },
  {
    extensionName: "Product page upsell",
    title: "Add Theme app extension to theme product page",
    subtitle: "Add the theme app extension to your theme product page",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: "Add App block",
        desp: "Click on ‘Add app block’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step1.png?alt=media&token=952d642f-5c61-4d21-afa8-08e2b37732c3",
      },
      {
        title: "Select Product Upsell",
        desp: "Click the ‘UpsellPlus PDP Upsell’ block",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step2.png?alt=media&token=63ec41b3-a506-4b89-9e08-475c9909494a",
      },
      {
        title: "Save",
        desp: "Click on ‘Save’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step3.png?alt=media&token=5537d485-66d8-4366-a24c-d34b980e5d2c",
      },
    ],
  },
  {
    extensionName: "Post-purchase upsell",
    title: "Enable UpsellPlus in Post-Purchase settings",
    subtitle: "Enable UpsellPlus in Post-Purchase settings",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/ce-preview.gif?alt=media&token=981bc3e9-d03f-40fa-adcd-0e154618026e",
    steps: [
      {
        title: "Go to Post-purchase page",
        desp: "Go to Post-Purchase settings and scroll to Post-purchase page",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step1.png?alt=media&token=56749299-efac-4056-b92a-3f38a896bd0b",
      },
      {
        title: "Select ‘UpsellPlus’",
        desp: "Select ‘UpsellPlus’ under Post-Purchase settings",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step2.png?alt=media&token=5764b34d-8730-443c-88ec-1af3d226131d",
      },
      {
        title: "Save",
        desp: "After making the changes be sure to click on ‘Save’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step3.png?alt=media&token=52790ea9-e3fe-43c1-ac93-dad0671af142",
      },
    ],
  },
  {
    extensionName: "Popup Upsell",
    title: "Preview Offer",
    subtitle: "Preview offer to make sure everything is working as expected",
    steps: [
      {
        title: "Go to Store",
        desp: "Click on the button below to go to store",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-popup-step1.png?alt=media&token=018bdc30-3da4-4b47-8e64-5716550cbd2b",
      },
      {
        title: "Confirm if you see the Cart Popup",
        desp: "Do you see the Cart popup?",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-popup-step2.png?alt=media&token=312269cb-e79b-4568-817d-e13607c50d40",
      },
    ],
  },
  {
    extensionName: "Cart Page Upsell",
    title: "Configure Upsell Offer",
    subtitle: "Add offer to the app section of your cart page",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/CartUpsellAddSection.gif?alt=media&token=d16804ab-065f-4dcb-bd1e-a260b15057af",
    steps: [
      {
        title: "Add section",
        desp: "Click on ‘Add section’ under ‘Template’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step1.png?alt=media&token=29c48050-d7d7-42fc-a06f-1e431be6e964",
      },
      {
        title: "Select ‘UpsellPlus Cart Upsell’",
        desp: "Click on ‘UpsellPlus Cart Upsell’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step2.png?alt=media&token=53af2a46-a844-4953-a05f-95c6ce4b9331",
      },
      {
        title: "Save",
        desp: "After you see the section, click on ‘Save’",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step3.png?alt=media&token=80682839-16c6-4528-8aa1-ba07a9297adf",
      },
    ],
  },
  {
    extensionName: "Cart Drawer Upsell",
    title: "Configure Upsell Offer",
    subtitle: "Connect your theme to UpsellPlus",
    steps: [
      {
        title: "View Setup Guide",
        desp: "Follow a step by step guide to set it up yourself",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-drawer-step2.png?alt=media&token=47e35006-da15-4e2c-8bb3-f60847c3e20d",
      },
      {
        title: "Or Request Setup",
        desp: "Click on ‘Request setup’ to talk to our team on live chat",
        image:
          "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-drawer-step1.png?alt=media&token=30dd347e-a2c2-46be-9ec7-d30cdca72856",
      },
    ],
  },
  {
    extensionName: "Custom field",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: 'Add App block',
        desp: 'Click ‘Add app block’',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step1.svg?alt=media&token=02e8c298-2a16-4547-85b9-df4d9dceef32'
      },
      {
        title: 'Select ‘UpsellPlus’',
        desp: 'Select the ‘Custom content’ block',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step2.svg?alt=media&token=8ddcd34f-c89a-44c1-a1ec-ad8774edcdc7'
      },
      {
        title: 'Save',
        desp: 'Save the checkout profile',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step3.svg?alt=media&token=edd8d7f9-2f7e-4442-b7fa-b9be43c07f3e'
      }
    ],
  },
  {
    extensionName: "Custom content",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: 'Add App block',
        desp: 'Click ‘Add app block’',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step1.svg?alt=media&token=02e8c298-2a16-4547-85b9-df4d9dceef32'
      },
      {
        title: 'Select ‘UpsellPlus’',
        desp: 'Select the ‘Custom content’ block',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step2.svg?alt=media&token=8ddcd34f-c89a-44c1-a1ec-ad8774edcdc7'
      },
      {
        title: 'Save',
        desp: 'Save the checkout profile',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step3.svg?alt=media&token=edd8d7f9-2f7e-4442-b7fa-b9be43c07f3e'
      }
    ],
  },
  {
    extensionName: "Rewards Bar",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: 'Add App block',
        desp: 'Click ‘Add app block’',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step1.svg?alt=media&token=02e8c298-2a16-4547-85b9-df4d9dceef32'
      },
      {
        title: 'Select ‘UpsellPlus’',
        desp: 'Select the ‘Rewards Bar’ block',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step2.svg?alt=media&token=8ddcd34f-c89a-44c1-a1ec-ad8774edcdc7'
      },
      {
        title: 'Save',
        desp: 'Save the checkout profile',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step3.svg?alt=media&token=edd8d7f9-2f7e-4442-b7fa-b9be43c07f3e'
      }
    ],
  },
  {
    extensionName: "Adress blocker",
    gif: "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014",
    steps: [
      {
        title: 'Add App block',
        desp: 'Click ‘Add app block’',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step1.svg?alt=media&token=02e8c298-2a16-4547-85b9-df4d9dceef32'
      },
      {
        title: 'Select ‘UpsellPlus’',
        desp: 'Select the ‘Adress blocker’ block',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step2.svg?alt=media&token=8ddcd34f-c89a-44c1-a1ec-ad8774edcdc7'
      },
      {
        title: 'Save',
        desp: 'Save the checkout profile',
        image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step3.svg?alt=media&token=edd8d7f9-2f7e-4442-b7fa-b9be43c07f3e'
      }
    ],
  },
]
const ButtonsWrapper = styled.div`
  padding-bottom: 60px;
`
function OnboardingLastStep(props) {
  const { backLink, extensionName, from, rules, id } = props
  const { firebase, shop, token, host } = useContext(FirebaseContext)
  const [active, setActive] = useState(false)
  const [toastActive, setToastActive] = useState(false)
  const [mainThemeId, setMainThemeId] = useState(null);
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData,
    }
  )
  const copyIdToClipboard = (e, id) => {
    e.stopPropagation()
    navigator.clipboard
      .writeText(id)
      .then(() => toggleActive())
      .catch((err) => console.log("Error in copy customization id: ", err))
  }
  const toggleActive = useCallback(
    () => setToastActive((active) => !active),
    []
  )
  

    const getThemeId = async () => {
        try {
            const fetchedThemeId = await getMainThemeId(token, shop, host);
            if (fetchedThemeId?.data?.mainThemeId) {
                setMainThemeId(fetchedThemeId.data.mainThemeId);
            }
        } catch (err) {
            console.log('Error in set main theme id: ', err);
        }
    }

    useEffect(() => {
        getThemeId().catch((err) => console.log('Error in fetch main theme id: ', err));
    }, []);
  const toastMarkup = toastActive ? (
    <Toast
      content="ID copied"
      onDismiss={toggleActive}
      duration={1500}
    />
  ) : null
  const domain = shopData && shopData.domain
  return (
      <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate(backLink)
                }}} 
                title={`Create ${extensionName}`}>
        {"onboarding" === from ? 
        <OnboardingForNew rules={rules} initStep={2} id={id} extensionName={"Cart Page Upsell" === extensionName ? "Cart upsell" : extensionName} cartUpsellName={"Cart Page Upsell" === extensionName ? "Cart Page Upsell":null}/>
        :
        <Layout>
          <Layout.Section>
            {/*
            <div style={{ margin: "20px 0 10px -25px" }}>
              <CreateOfferSteps step={3} />
            </div>
            */}
            <Box paddingBlockEnd="800">
            <LegacyCard>
              <LegacyCard.Section>
                <OnboardingLastStepWrapper>
                  <div className="instructions">
                    <div className="instructions-image">
                      <div>
                        <img src={STEP.find( (s) => s.extensionName === extensionName) !== undefined ? STEP.find( (s) => s.extensionName === extensionName).gif : "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014" } />
                      </div>
                    </div>
                        <Text variant="bodyLg" as="p">Last step!</Text>
                        { "Post-purchase upsell" === extensionName ?
                        <Text variant="bodyLg" as="p">Enable <b>UpsellPlus</b> in Post-Purchase settings</Text>
                        :
                          "Cart Page Upsell" === extensionName ?
                          <Text variant="bodyLg" as="p">Add <b>{extensionName}</b> to the app section of your cart page</Text>
                          :
                            "Product page upsell" === extensionName ?
                            <Text variant="bodyLg" as="p">Add the  <b>Theme app extension</b> to your theme product page</Text>
                            :
                          <Text variant="bodyLg" as="p">Enable the <b>{extensionName} extension</b> in your checkout</Text>
                        }
                  </div>
                  {id &&
                    <div style={{padding:'15px 0'}}>
                      <LegacyStack distribution="center">
                        <div>Customization ID: {id}</div>
                        <div style={{cursor:'pointer'}} onClick={(e) => copyIdToClipboard(e, id)}>
                          <Icon source={ClipboardIcon} tone="base"/>
                        </div>
                      </LegacyStack>
                    </div>
                    }
                  <div className="action-buttons">
                    <LegacyStack distribution="center" alignment="center" spacing="tight">
                    { "Post-purchase upsell" === extensionName ?
                      <Button
                      onClick={() => {
                        setActive(true)
                        window.open(
                          `https://${domain}/admin/settings/checkout#additionalscripts`,
                          "_blank"
                        )
                      }}
                      variant="primary"
                      icon={ExternalIcon}
                    >Go to Post-Purchase Settings
                    </Button>
                    :
                    "Cart Page Upsell" === extensionName ?
                      <Button
                        onClick={() => {
                          setActive(true)
                          window.open(
                            `https://${shop}/admin/themes/${mainThemeId}/editor?template=cart&addAppBlockId=553fb951-397c-4171-8b32-7056a23ffd82/upsell-app-block`,
                            "_blank"
                          )
                        }}
                        variant="primary"
                        icon={ExternalIcon}
                      >Go to theme editor
                      </Button>
                      :
                      "Product page upsell" === extensionName ?
                      <Button
                        onClick={() => {
                          setActive(true)
                          window.open(
                            `https://${shop}/admin/themes/${mainThemeId}/editor?template=product&addAppBlockId=553fb951-397c-4171-8b32-7056a23ffd82/upsell-pdp-block&target=mainSection`,
                            "_blank"
                          )
                        }}
                        variant="primary"
                        icon={ExternalIcon}
                      >Go to theme editor
                      </Button>
                      :
                      <Button
                        onClick={() => {
                          setActive(true)
                          window.open(
                            `https://${domain}/admin/settings/checkout/editor`,
                            "_blank"
                          )
                        }}
                        variant="primary"
                        icon={ExternalIcon}
                      >Go to checkout editor
                      </Button>
                      }
                    </LegacyStack>
                    
                  </div>
                </OnboardingLastStepWrapper>
              </LegacyCard.Section>
              <CardDivider />
              <Box paddingInline="400" paddingBlock="800" >
              <Box paddingBlockEnd="800">
                <Text variant="bodyLg" as="p" fontWeight="bold" style={{padding:'15px 0'}} alignment="center">Follow the steps below and get your {extensionName} ready</Text>
              </Box>
              <InlineGrid
                      gap="100"
                      columns={"Checkout upsell" === extensionName ? { xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }:{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                    >
                      {STEP.find(
                        (s) =>
                          s.extensionName === extensionName
                      ).steps.map((step, index) => {
                        return (
                          <Card>
                            <Box padding="200">
                              <BlockStack gap="100">
                                <img src={step.image} alt={step.title} />
                                <Text as="p" fontWeight="semibold">
                                  {"Cart Drawer Upsell" === extensionName
                                    ? `${step.title}`
                                    : `step ${index + 1}: ${step.title}`}
                                </Text>
                                <Text as="p">{step.desp}</Text>
                              </BlockStack>
                            </Box>
                          </Card>
                        )
                      })}
                    </InlineGrid>
                    </Box>
                    <ButtonsWrapper>
                      <LegacyStack distribution="center">
                        <ButtonGroup>
                          <Button
                            variant="plain"
                            onClick={() => {
                              if (window && window.$crisp) {
                                window.$crisp.push(["do", "chat:open"])
                              }
                            }}
                          >Get help
                          </Button>
                          {active ? (
                            <Button
                              onClick={() => {
                                navigate(backLink, { replace: true })
                              }}
                            >
                              Mark as done
                            </Button>
                          ) : (
                            <Button disabled>
                              Mark as done
                            </Button>
                          )}
                        </ButtonGroup>
                      </LegacyStack>
                    </ButtonsWrapper>
            </LegacyCard>
            </Box>
          </Layout.Section>
        </Layout>
  }
  <FrameWrapper>
  <Frame>{toastMarkup}</Frame>
  </FrameWrapper>
      </Page>
      
  );
}

export default OnboardingLastStep
