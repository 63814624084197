import React, {
    Fragment,
    useState,
    useCallback,
    useEffect,
  } from 'react'
  import update from 'immutability-helper'
  import equal from 'deep-equal'
  import { 
    Badge,
    Button,
    ButtonGroup,
    Form,
    Layout,
    Page,
    Toast,
    Frame,
    LegacyStack,
  } from "@shopify/polaris"
  import { withFirebase } from '../../../providers/firebase'
  import { doc } from 'firebase/firestore';
  import { useDocumentData } from 'react-firebase-hooks/firestore';
  import { navigate } from "gatsby"
  import ProductPreview from './product/productPreview'
  import './create.css'
  import Constants from '../../../helpers/constants'
  import createRule from '../../../helpers/createRule'
  import { updateRule, deleteRule } from '../../../helpers/updateRule'
  import ProductOfferType from './product/productOfferType'
  import getSymbolFromCurrency from 'currency-symbol-map';
  import styled from "styled-components";
  import createAutomaticDiscount from '../../../helpers/createAutomaticDiscount';
  import removeDiscount from '../../../helpers/removeDiscount';
  import PageHeader from '../../../components/pageHeader';
  import './common.css';

  const FrameWrapper = styled.div`
    .Polaris-Frame {
      max-height: 0;
      min-height: 0;
    }
  `
  function CreateProduct(props) {
    const { token, shop, location, firebase, host } = props
    const existingOffer = (location && location.state && location.state.rule) || null
    const rules = location && location.state && location.state.rules
    const from = location && location.state && location.state.from
    const versionAnalytics = location && location.state && location.state.versionAnalytics
    const isEditing = !!existingOffer
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingOffer && existingOffer.id || null, // always null if not created
      offerType: existingOffer && existingOffer.offerType || 'Product',
      addToCartAction: existingOffer && existingOffer.addToCartAction || null,
      name: existingOffer && existingOffer.name || '',
      title: existingOffer && existingOffer.title || '',
      subtitle: existingOffer && existingOffer.subtitle || '',
      cta: existingOffer && existingOffer.cta || 'Add to cart',
      compareAtPrice: existingOffer && existingOffer.compareAtPrice || {sym:'$',value:""},
      offerBackgroundColor: existingOffer && existingOffer.offerBackgroundColor || '#ffffff',
      //TODO we should remove some customization setting which are for checkout.liquid that are not supported in CE
      introText: existingOffer && existingOffer.introText || 'You may also like',
      introTextColor: existingOffer && existingOffer.introTextColor || '#000000',
      topBarBackgroundColor: existingOffer && existingOffer.topBarBackgroundColor || '#FFFFFF',
      popupBorderRadius: existingOffer && existingOffer.popupBorderRadius !== undefined ? existingOffer.popupBorderRadius : 2,
      title: existingOffer && existingOffer.title || '',
      titleColor: existingOffer && existingOffer.titleColor || '#000000',
      description: existingOffer && existingOffer.description || '',
      descriptionColor: existingOffer && existingOffer.descriptionColor || '#000000',
      cta: existingOffer && existingOffer.cta || '',
      discount: existingOffer && existingOffer.discount || {sym:'$',value:""},
      bodyBackgroundColor: existingOffer && existingOffer.bodyBackgroundColor || '#FFFFFF',
      ctaBackgroundColor: existingOffer && existingOffer.ctaBackgroundColor || '#0CA126',
      ctaBorderColor: existingOffer && existingOffer.ctaBorderColor || '#B6B6B6',
      ctaButtonTextColor: existingOffer && existingOffer.ctaButtonTextColor || '#ffffff',
      buttonBorderRadius: existingOffer && existingOffer.buttonBorderRadius !== undefined ? existingOffer.buttonBorderRadius : 2,
      buttonBorderThickness: existingOffer && existingOffer.buttonBorderThickness !== undefined ? existingOffer.buttonBorderThickness : 0,
      buttonHeight: existingOffer && existingOffer.buttonHeight !== undefined ? existingOffer.buttonHeight : 35,
      checkoutText: existingOffer && existingOffer.checkoutText || '',
      checkoutTextColor: existingOffer && existingOffer.checkoutTextColor || '#0CA127',
      product: existingOffer && existingOffer.product || [],
      productNumber: existingOffer && existingOffer.productNumber !== undefined ? existingOffer.productNumber : 1,
      variant: existingOffer && existingOffer.variant || null,
      variants: existingOffer && existingOffer.variants || null,
      enabled: existingOffer && existingOffer.enabled || false,
      isExcludeOffer: existingOffer && existingOffer.isExcludeOffer || false,
      triggers: existingOffer && existingOffer.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
      },
    variantsTriggers: existingOffer && existingOffer.variantsTriggers || {
      if:{
        all:[
            {fact:'cartProducts',operator: 'hasAny',value: null},
            {fact:'cartProducts',operator: 'Variant', value: null}
        ]
      },
      then: {
        all:[
          {fact:'upsellVariant',operator: 'Variant',value: null}
        ]
      },
      swap: false,
      type: 'manual'
    },
      shopBrain: existingOffer && existingOffer.shopBrain && (existingOffer.shopBrain.inclusion ? existingOffer.shopBrain: {inclusion:[{setting: 'tags',value: null}],exclusion: existingOffer.shopBrain.exclusion}) || null,
      aiConditions: existingOffer && existingOffer.aiConditions || {
        customerTags: [],
        products: []
      },
      swap: existingOffer && existingOffer.swap || false,
      swapQuantity: existingOffer && existingOffer.swapQuantity || null,
      versionB: existingOffer && existingOffer.versionB || null,
      imageWidthHeight: existingOffer && existingOffer.imageWidthHeight || "64",
      imagePosition: existingOffer && existingOffer.imagePosition || 'cover',
      imagePadding: existingOffer && existingOffer.imagePadding || "1",
      autoAdd: existingOffer && existingOffer.autoAdd || false,
      isExcludeProduct: existingOffer && existingOffer.isExcludeProduct || false,
      swapPrice: existingOffer?.swapPrice || false,
      // discountCode: existingOffer?.discountCode || {
      //   enabled: false,
      //   value: null,
      // },
      ruleFeatures: existingOffer?.ruleFeatures || {
        isDiscountFunctionEnabled: false,
      },
    }
    const [state, setState] = useState(initialEditingState)
    const [version, setVersion] = useState(state.versionB ? "A":null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [error, setError] = useState(null)
    const [productEdit, setProductEdit] = useState(-1)
    const [toastActive, setToastActive] = useState({active: false, message: ""});
    const [customizationEdit, setCustomizationEdit] = useState(false)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
    );
    const [productOfferType, setProductOfferType] = useState(() => {
      if (state?.variantsTriggers?.type) {
        return state?.variantsTriggers?.type;
      } else {
        return state.shopBrain ? 'manual' : 'ai';
      }
    });
    const [shopBrainType, setShopBrainType] = useState(() => {
      if (state?.variantsTriggers?.shopBrainType) {
        return state?.variantsTriggers?.shopBrainType;
      } else {
        return 'related';
      }
    });

    const [versionData, setVersionData] = useState({
      subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
      product: state.product, variantsTriggers: state.variantsTriggers,
  })

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

    const handleProductOfferTypeChange = (_checked, newValue, shopBrainType) => {
      setProductOfferType(newValue)
      const variantsTriggers = {
        if:{
          all:[
            {fact:'cartProducts',operator: 'hasAny',value: null },
            {fact:'cartProducts',operator: 'Variant', value: null}
          ]
        },
        then: {
          all:[
            {fact:'upsellVariant',operator: 'Variant',value: null}
          ]
        },
        swap: false,
        type: newValue,
        shopBrainType: shopBrainType
      }

      if("manual" !== newValue){
        if(existingOffer && existingOffer.shopBrain){
            if(existingOffer.variantsTriggers && ["ai", "autopilot"].includes(existingOffer.variantsTriggers.type)){
              const inclusion = [{setting: newValue === 'autopilot' ? 'collection' : 'tags', value: null}];
              const exclusion = [{setting: 'tags', value: null}];

              setState(update(state, { shopBrain: { $set: { inclusion, exclusion } }, variantsTriggers:{$set:{
                    ...existingOffer.variantsTriggers,
                    type: newValue,
                    shopBrainType: shopBrainType
                  }}}))
            }else{
            setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }, variantsTriggers:{$set:variantsTriggers}}))
            }
        }else{
          const inclusion = [{setting: newValue === 'autopilot' ? 'collection' : 'tags', value: null}];
          const exclusion = [{setting: 'tags', value: null}];

          if(existingOffer && existingOffer.variantsTriggers && ["ai", "autopilot"].includes(existingOffer.variantsTriggers.type)){
            setState(update(state, { shopBrain: { $set: { inclusion, exclusion} }, variantsTriggers:{$set:existingOffer.variantsTriggers}}))
          }else{
            setState(update(state, { shopBrain: { $set: { inclusion, exclusion} }, variantsTriggers:{$set:variantsTriggers}}))
          }
        }
      }else{
        if(existingOffer && existingOffer.variantsTriggers && "manual" === existingOffer.variantsTriggers.type){
          setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:existingOffer.variantsTriggers} }))
        }else{
          if(Array.isArray(state.product) && state.product.length > 0 && state.product[0].product.options){
            setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:variantsTriggers} }))
          }else{
            setState(update(state, { shopBrain: { $set: null }}))
          }
        }
      }
    } 


    let currentState = Object.assign({},state);
    let initialStateForCheck = Object.assign({},initialEditingState);

    if("B"===version){
      currentState = {...state, ...versionData, versionB:{
        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
        product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
        textStyles: state.textStyles, swapPrice: state.swapPrice,
    }}
    }

    delete currentState.enabled;
    delete initialStateForCheck.enabled;

    const hasUnsavedChanges = (isCreating || isEditing) && !equal(currentState, initialStateForCheck)
    const handleRuleDelete = async () => {
      await deleteRule(state.id, token, shop, firebase);
      await removeDiscount(token, shop, host, {offerId: state.id});
  
      navigate('/app/offers', {
        state: {
          ...location.state,
          tabIndex: Constants.TAB_INDEX.OFFER_LIST
        },
        replace: true,
      })
    }

    const toggleActive = useCallback(() => setToastActive((prevState) => ({...prevState, active: !prevState.active})), []);
    const toastMarkup = toastActive.active ? (
      <Toast content={toastActive.message} error onDismiss={toggleActive} />
    ) : null;

    const handleFormSubmit = async (type) => {
      console.log("type", type)
      if (isSubmitting) {
        return
      }
      if((state.product === null || (Array.isArray(state.product) && (state.product.length === 0 || (state.product.length === 1 && !state.product[0].product)))) && state.shopBrain === null ){
        setError({product: "There is no product selected. Nothing will show in your checkout."})
        return "error"
      }else{
        if(error != null){
          setError(null)
        }
      }
      setIsSubmitting(true)
  
      try {
        
        if (isEditing) {
          // TODO
          console.log('update rule.')
          await updateRule({
            ...("B"===version? {...state, ...versionData, versionB:{
              subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
              product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
              textStyles: state.textStyles, swapPrice: state.swapPrice,
          }}: state) /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
          }, token, shop, firebase);


          if (state.product && Array.isArray(state.product) && state.product.length > 0) {
            if (state?.variantsTriggers?.type === "manual" && state?.ruleFeatures?.isDiscountFunctionEnabled) {
              await createAutomaticDiscount(token, shop, host, {offerId: state.id, products: state.product.filter((product) => product?.compareAtPrice)});
            } else {
              await removeDiscount(token, shop, host, {offerId: state.id});
            }
          }
  
          navigate(
            `/app/offers/createProduct`,
            {
              state: {
                rule: "B"===version? {...state, ...versionData, versionB:{
                  subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
                  product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
                  textStyles: state.textStyles, swapPrice: state.swapPrice,
              }, updatedAt: new Date().toISOString()}: {...state, updatedAt: new Date().toISOString()},
              from: "Changes saved",
              },
              replace: true,
            }
          )
        } else {
                 console.log(state)
                //  console.log(...state)
          const result = await createRule({
            ...("B"===version? {...state, ...versionData, versionB:{
              subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
              product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
              textStyles: state.textStyles, swapPrice: state.swapPrice,
          }}: state) /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            enabled: "save" === type ? false : true,
          }, token, shop, host)

          if (state?.ruleFeatures?.isDiscountFunctionEnabled
            && state?.variantsTriggers?.type === "manual"
            && state.product
            && Array.isArray(state.product)
            && state.product.length > 0
          ) {
            await createAutomaticDiscount(token, shop, host, {offerId: state.id ? state.id : result.data.id, products: state.product.filter((product) => product?.compareAtPrice)});
          }
         
          console.log('data', result)
          if(result && result.data && result.data.rules.filter( rule => "Product" === rule.offerType && !rule.archived ).length > 1){
            navigate(
              `/app/offers/productOfferItem`,
              {
                state: {
                  rule: result.data.rules.find(rule => rule.id === result.data.id),
                  from: "Offer saved",
                },
                replace: true,
              }
            )
          } else {
              setTimeout(() => {
                navigate(
                  `/app/offers/offerOnboarding`,
                  {
                    state: {
                      backLink:"/app/offers", extensionName:"Product page upsell", from: from, rules: rules
                    },
                    replace: true,
                  }
                )
              }, 1000)
            }
            console.log('result from creating rule: ', result)
          }
        // }
      }
      catch (e) {
        console.log('Error creating rule: ', e)
      }
  
      setIsSubmitting(false)
    }
  
    const addSmartRule =  () => {
      const conditions = {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
    }
      setState(update(state, { triggers: { $set: conditions }}))
    }

    
    const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
    const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
    const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
    /*isEditing needs to be fixed, it always call React Hook "useState" is called conditionally error*/

    
    return (
      <PageHeader 
        title={initialEditingState.name} 
        location={location}
        link={"/app/offers"}
        backLinkForNew={"/app/offers/createOfferType"}
        from={from}
        existingOffer={existingOffer}
        token={token} 
        shop={shop} 
        firebase={firebase} 
        state={state} 
        setState={setState} 
        hasUnsavedChanges={hasUnsavedChanges} 
        handleFormSubmit={handleFormSubmit}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        windowDimensions={windowDimensions}
        initialEditingState={initialEditingState}
        >
        
        <Layout>
          <Layout.Section>          
            <div style={{ marginBottom: 50, }}>
              <Form 
                    disabled={
                      !state.product || 
                      !state.name ||
                      !state.title
                    }
                    onSubmit={handleFormSubmit}>
                <ProductOfferType host={host} token={token} shopBrainType={shopBrainType} productOfferType={productOfferType} handleProductOfferTypeChange={handleProductOfferTypeChange} shop={shop} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} versionData={versionData} setVersionData={setVersionData} version={version} setVersion={setVersion} versionAnalytics={versionAnalytics} locale={locale} setProductOfferType={setProductOfferType} existingOffer={existingOffer} customizationEdit={customizationEdit} setCustomizationEdit={setCustomizationEdit}/>             
              </Form>
            </div>
          </Layout.Section>
          <Layout.Section variant="oneThird">
              <div
                style={windowDimensions?.width < 795 ? {}:{
                  maxWidth: 311.34,
                  position: 'fixed',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: "100vh",
                  paddingBottom: "100px",
                }}
              >
              <ProductPreview 
                {...state} 
                {...props}
                checkoutOfferType={productOfferType}
                productEdit={productEdit}
                image={state.product && state.product.image}
                price={state.variant && state.variant.price}
                shopData={shopData}
              />
            </div>
          </Layout.Section>    
        </Layout>
            

        <FrameWrapper>
          <Frame >
            {toastMarkup}
          </Frame>
        </FrameWrapper>
      </PageHeader>
    );
  }
  
  export default withFirebase(CreateProduct);