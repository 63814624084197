import React, {useState, useCallback, Fragment} from 'react'
import {
  LegacyCard,
  FormLayout,
  InlineError,
  TextField,
  Icon,
  LegacyStack,
  Button,
  Text
} from "@shopify/polaris"
import {
  DeleteIcon,
  DragHandleIcon,
} from '@shopify/polaris-icons';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import ProductPickerModal from '../../../../components/productPickerModal'
import selectPayloadToData from '../../../../helpers/selectPayloadToData'
import selectPayloadToCEData from '../../../../helpers/selectPayloadToCEData';
import VersionColor from './versionColor';
import update from 'immutability-helper'
import styled from 'styled-components'
// import DiscountWarningBanner from '../common/discountWarningBanner/discountWarningBanner';
import DiscountCart from '../common/discountCart/discountCart';
import ProductEdit from './productEdit';
import CardDivider from '../../../../components/cardDivider';
import CardWrapper from '../../../../components/cardWrapper';

const DeleteWrapper = styled.div`
    cursor: pointer;
`
function OfferProductsSetting(props) {
    const { host, version, shop, state, setState, productPickerShow, error, setError, productEdit, setProductEdit, currency, currencySymbol, token } = props
    const [productPickerVisible, setProductPickerVisible] = useState(false)
    const [isDisplayDiscount, setIsDisplayDiscount] = useState(false)

    const addAnotherProduct = () => {
      if(state.product && !Array.isArray(state.product)){
        const productItem = {
          product: state.product,
          variants: state.variants ? state.variants :(state.variant ? [state.variant]:[]),
          title: state.title,
          subtitle: state.subtitle,
          description: state.description,
          compareAtPrice: state.compareAtPrice && state.compareAtPrice?.sym ? state.compareAtPrice : {sym: '$', value:state.compareAtPrice}
        }
        setState(update(state, {
          product: { $set: [productItem, {}] },
        }))
        setProductEdit(1)
      }else{
        const productSize = state.product.length
        setState(update(state,{product:{$push: [{}]}}))
        setProductEdit(productSize)
      }
    }
    const deleteOneProduct = (index) => {
      if (state.product.length === 2 && 'tiles' === state.generalLayout && 'row' === state.layout) {
        setState(update(state, { product: { $splice: [[index, 1]]}, layout: { $set: 'carousel' }, generalLayout: { $set: 'standard' }}))
      }else{
        setState(update(state, { product: { $splice: [[index, 1]]}}))
      }
    }

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
  
      const items = Array.from(state.product);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      setState(update(state, { product: { $set: items } }));
    };
    return (
      <CardWrapper title="Product" subtitle="Product Title, Product Subtitle, Marketing Message, Compare at Price">
        
            <DragDropContext onDragEnd={onDragEnd}>
              {Array.isArray(state.product) ? (
              <Droppable droppableId="products">
                {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {state.product.map((p,index) => (
                    <Draggable key={index} draggableId={`product-${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {productEdit == index ?
                            <ProductEdit key={index} index={index} p={p} state={state} setState={setState} currency={currency} currencySymbol={currencySymbol} token={token} shop={shop} host={host} productPickerShow={productPickerShow} error={error} setError={setError} version={version} setProductEdit={setProductEdit}/>
                            :
                          <VersionColor>
                            <div className={version ? ("A"===version?'version-a':'version-b'):''}>
                              <LegacyCard sectioned key={index}>
                                  <LegacyStack alignment="center">
                                    <div {...provided.dragHandleProps}>
                                      <LegacyStack.Item>
                                        <Icon
                                          source={DragHandleIcon}
                                          tone="subdued"
                                        />
                                      </LegacyStack.Item>
                                    </div>
                                    <LegacyStack.Item fill>
                                      <LegacyStack alignment="center">
                                      <img className="upsellplus-image" src={  p.product && p.product.image || 'https://via.placeholder.com/200'} style={{width: '20px', height: '20px'}} />
                                      <Text as="h2" variant="headingMd">product {index+1} : {p.product ? p.product.title: "please choose product"}</Text>
                                      </LegacyStack>
                                    </LegacyStack.Item>
                                    <LegacyStack.Item>
                                      <LegacyStack alignment="center">
                                        <Button variant="plain" onClick={()=>{setProductEdit(index); setIsDisplayDiscount(false)}}>
                                          Edit
                                        </Button>
                                        <DeleteWrapper onClick={()=>deleteOneProduct(index)}>
                                          <Icon
                                            source={DeleteIcon}
                                            tone="critical"/>
                                        </DeleteWrapper>
                                      </LegacyStack>
                                    </LegacyStack.Item>
                                  </LegacyStack>
                              </LegacyCard>
                            </div>
                          </VersionColor>
                        }
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div style={{marginTop: '20px'}} className='another-product-step'>
                    <Button
                      onClick={addAnotherProduct}
                      variant='primary'>+ {state.product.length > 0 ? 'Add another product':'Add a product'}</Button>
                    {
                      error && error.product ?
                      <div style={{marginTop:'15px'}}>
                        <InlineError message={error.product} fieldID="product-error" />
                      </div>
                      :
                      false
                    }
                  </div>
                  {provided.placeholder}
                </div>
              )}
              </Droppable>
              )
              :
              <div>
              <VersionColor>
              <div className={version ? ("A"===version?'version-a':'version-b'):''}>
              <LegacyCard sectioned>
                <Text as="h2" variant="headingMd">product 1</Text>
                <FormLayout>
                    <Fragment>
                        <div className='mockInputContainer'>
                          <p className='mockInputLabel' style={{paddingTop:'10px'}}>Offer product</p>
                          <div
                            className='mockInput'
                            onClick={() => setProductPickerVisible(true)}
                          >
                            {
                              ((!state.variant && !state.variants) || !state.product) ?
                                <span>Select a product</span> :
                                <div>
                                  <img
                                    src={state.product.image}
                                    className='mockInputImage'
                                  />
                                  {state.variants ?
                                    <span>{state.product.title} | {state.variants.map(variant => variant.title).join("/")}</span>
                                    :
                                    <span>{state.product.title} | {state.variant.title}</span>
                                  }
                                </div>
                            }
                          </div>
                          {
                            error && error.product ?
                            <InlineError message={error.product} fieldID="product-error" />
                            :
                            false
                          }
                        </div>
                        { "product" === productPickerShow ?
                        <ProductPickerModal
                          shop={shop}
                          host={host}
                          token={token}
                          open={productPickerVisible}
                          onSelect={(selectPayload) => {
                            let data
                            if("extension" !== state.checkoutType){
                              data = selectPayloadToData(selectPayload)
                            }else{
                              data = selectPayloadToCEData(selectPayload)
                            }
                            if (!data) {
                              if (state.product) {
                                // Clear selection if one exists
                                setState(update(state, {
                                  product: { $set: null },
                                  variant: { $set: null },
                                  variants: { $set: null },
                                }))
                              }
                              return
                            }

                            setState(update(state, {
                              product: { $set: data.product },
                              variants: { $set: data.variants },
                            }))
                          }}
                          onClose={() => setProductPickerVisible(false)}
                          initialProduct={state?.product ? {product:state.product, variants: state.variants}:null || null}
                        />
                        :
                        false
                        }
                      </Fragment>
                      <TextField
                        label="Offer Product Title"
                        placeholder="Breath Mints"
                        onChange={(val) => setState(update(state, { title: { $set: val }})) }
                        value={state.title}
                      />
                      <TextField
                        label="Offer Product Subtitle"
                        placeholder="Add on mints for 15% off!"
                        onChange={(val) => setState(update(state, { subtitle: { $set: val }})) }
                        value={state.subtitle}
                      />
                      <TextField
                        label="Marketing message"
                        placeholder="Get this exclusive deal now!"
                        onChange={(val) => setState(update(state, { description: { $set: val }})) }
                        value={state.description}
                      />
                      <LegacyCard sectioned>
                        <DiscountCart state={state} setState={setState} currency={currency} currencySymbol={currencySymbol}/>
                      </LegacyCard>
                    </FormLayout>
              </LegacyCard>
              </div>
              </VersionColor>
              <div style={{marginTop: '20px'}}>
                <Button
                  onClick={addAnotherProduct}
                  variant='primary'>+ Add another product</Button>
              </div>
              </div>
              }
          </DragDropContext>
          </CardWrapper>
    )
}

export default OfferProductsSetting;