import React, { useState } from 'react';
import { Banner, Box, Link } from "@shopify/polaris";
const DiscountWarningBanner = ({isAi = false}) => {

  const [bannerActive, setBannerActive] = useState(isAi ?(localStorage.getItem('close_ai_discount_banner') ? false : true) : (localStorage.getItem('close_discount_banner') ? false : true))

  const content = isAi
    ? 'All AI recommended products will show this compare at price. Add a Shopify Script to your Script Editor app to make the discount apply.'
    : 'This only adds a ‘compare at’ price. Add a Shopify Script to your Script Editor app to apply a discount.';

  return (
    <Box paddingBlockStart="200">
    {bannerActive &&
      <Banner 
      onDismiss={() => {
        setBannerActive(false); 
        if(isAi){ 
          localStorage.setItem('close_ai_discount_banner', true) 
        }else{
         localStorage.setItem('close_discount_banner', true)
        }
        }}
      >
        <p>{content} <Link url='https://help.upsellplus.com/en/article/intro-to-discounts-yguu96/' target='_blank'>Go to Script templates</Link></p>
      </Banner>
      } 
      </Box>
  );
};

export default DiscountWarningBanner;