import React, {useState} from 'react';
import update from 'immutability-helper'
import {
  TextField,
  Popover,
  FormLayout,
  ColorPicker,
  RangeSlider,
  LegacyStack,
  RadioButton,
  Tooltip,
  Icon,
  TextContainer,
  Text,
} from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
import {throttle} from 'lodash'
import styled from 'styled-components'

const InfoIconWrapper = styled.div`
`
const ResponsiveRadio = styled.div`
  max-width: 135px;
`

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

const CardTab = (props) => {
  const {state, setState} = props;
  const [ctaTextColorPopoverVisible, setCtaTextColorPopoverVisible] = useState(false)
  const [ctaTextColorHsl, setCtaTextColorHsl] = useState({
    hue: 211,
    saturation: 39,
    brightness: 21,
    alpha: 1,
  })
  const [offerBackgroundColorPopoverVisible, setOfferBackgroundColorPopoverVisible] = useState(false)
  const [offerBackgroundColorHsl, setOfferBackgroundColorHsl] = useState({
    hue: 211,
    saturation: 0,
    brightness: 100,
    alpha: 1,
  })
  const [widgetBorderColorPopoverVisible, setWidgetBorderColorPopoverVisible] = useState(false)
  const [widgetBorderColorHsl, setWidgetBorderColorHsl] = useState({
    hue: 0,
    saturation: 0,
    brightness: 85,
    alpha: 1,
  })
  const ctaTextColorActivatorButton = (
    <TextField
      label="Customize title and subtitle text color"
      onFocus={() => setCtaTextColorPopoverVisible(true)}
      value={state.ctaTextColor}
      onChange={(val) => setState(update(state, {ctaTextColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.ctaTextColor
          }}
          onClick={() => setCtaTextColorPopoverVisible(true)}
        />
      }
    />
  )
  const onCtaTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaTextColorHsl(color)
    setState(update(state, {ctaTextColor: {$set: hex}}))
  }
  const onCtaTextColorChangeThrottled = throttle(onCtaTextColorChange, 250)
  const offerBackgroundColorActivatorButton = (
    <TextField
      label="Customize offer card background color"
      onFocus={() => setOfferBackgroundColorPopoverVisible(true)}
      value={state.offerBackgroundColor}
      onChange={(val) => setState(update(state, {offerBackgroundColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.offerBackgroundColor
          }}
          onClick={() => setOfferBackgroundColorPopoverVisible(true)}
        />
      }
    />
  )
  const onOfferBackgroundColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
      saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
      brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    setOfferBackgroundColorHsl(color)
    setState(update(state, {offerBackgroundColor: {$set: hex}}))
  }
  const onOfferBackgroundColorChangeThrottled = throttle(onOfferBackgroundColorChange, 250)
  const widgetBorderColorActivatorButton = (
    <TextField
      label="Customize offer card border color"
      onFocus={() => setWidgetBorderColorPopoverVisible(true)}
      value={state.widgetBorderColor}
      onChange={(val) => setState(update(state, {widgetBorderColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.widgetBorderColor
          }}
          onClick={() => setWidgetBorderColorPopoverVisible(true)}
        />
      }
    />
  )
  const onWidgetBorderColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || widgetBorderColorHsl.hue || 0,
      saturation: updatedColor.saturation || widgetBorderColorHsl.saturation || 0,
      brightness: updatedColor.brightness || widgetBorderColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )
    setWidgetBorderColorHsl(color)
    setState(update(state, {widgetBorderColor: {$set: hex}}))
  }

  const onWidgetBorderColorChangeThrottled = throttle(onWidgetBorderColorChange, 250)
  const suffixStyles = {
    minWidth: '24px',
    textAlign: 'right',
  };
  const handleLayoutChange = (_checked, newValue) => setState(update(state, {layout: {$set: newValue}}))

  return (
    <FormLayout>
      <div>
        <TextContainer spacing="loose">
          <Text  variant="bodyMd" as="span" fontWeight="semibold">Product layout</Text>
        </TextContainer>
        <LegacyStack>
          <RadioButton
            label="Carousel"
            checked={state.layout === 'carousel'}
            id="carousel"
            name="layout"
            onChange={handleLayoutChange}
          />
          <RadioButton
            label="Stacked"
            id="stacked"
            name="layout"
            checked={state.layout === 'stacked'}
            onChange={handleLayoutChange}
          />
          <ResponsiveRadio>
            <RadioButton
              label="Mobile: carousel Desktop: stack"
              id="responsive"
              name="layout"
              checked={state.layout === 'responsive'}
              onChange={handleLayoutChange}
            />
          </ResponsiveRadio>
          <InfoIconWrapper>
            <Tooltip content="Optimize for mobile and desktop. Shows based on screensize" dismissOnMouseOut>
              <Icon
                source={InfoIcon}
                tone="base"/>
            </Tooltip>
          </InfoIconWrapper>
        </LegacyStack>
        <LegacyStack>
          <div style={{padding: '10px 10px 0 10px'}}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Carousel_icon.svg?alt=media&token=5ff293de-fef5-440a-944b-5abc8a4936e6"
              alt="carousel layout"/>
          </div>
          <div style={{padding: '10px 10px 0 10px'}}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Stack_icon.svg?alt=media&token=d00cbd98-93ed-4cdf-8a12-7386ecf59297"
              alt="stack layout"/>
          </div>
          <div style={{padding: '10px 10px 0 30px'}}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/desktop_stack_mobile_carousel.svg?alt=media&token=e5527845-435a-4252-9c72-acfd4c8c24a6"
              alt="row layout"/>
          </div>
        </LegacyStack>
      </div>

      <TextField
        label="Set a title for all autopilot products"
        placeholder="Product title"
        onChange={(val) => setState(update(state, {title: {$set: val}}))}
        value={state.title}
      />

      <TextField
        label="Set a description for all autopilot products"
        placeholder="Product subtitle"
        onChange={(val) => setState(update(state, {subtitle: {$set: val}}))}
        value={state.subtitle}
      />

      <p>Dynamic variables you can use: [cart product title], [cart variant title], [upsell product title], [upsell
        variant title]</p>

      <Popover
        active={ctaTextColorPopoverVisible}
        activator={ctaTextColorActivatorButton}
        onClose={() => setCtaTextColorPopoverVisible(false)}
      >
        <ColorPicker
          color={ctaTextColorHsl}
          onChange={onCtaTextColorChangeThrottled}
        />
      </Popover>
      <Popover
        active={offerBackgroundColorPopoverVisible}
        activator={offerBackgroundColorActivatorButton}
        onClose={() => setOfferBackgroundColorPopoverVisible(false)}
      >
        <ColorPicker
          color={offerBackgroundColorHsl}
          onChange={onOfferBackgroundColorChangeThrottled}
        />
      </Popover>
      <Popover
        active={widgetBorderColorPopoverVisible}
        activator={widgetBorderColorActivatorButton}
        onClose={() => setWidgetBorderColorPopoverVisible(false)}
      >
        <ColorPicker
          color={widgetBorderColorHsl}
          onChange={onWidgetBorderColorChangeThrottled}
        />
      </Popover>

      <RangeSlider
        label="Customize offer card border radius"
        prefix={<p>px</p>}
        suffix={<p style={suffixStyles}>{state.widgetBorderRadius}</p>}
        value={state.widgetBorderRadius}
        onChange={(val) => setState(update(state, {widgetBorderRadius: {$set: val}}))}
        output
      />

      <RangeSlider
        label="Customize offer card border thickness"
        prefix={<p>px</p>}
        suffix={<p style={suffixStyles}>{state.widgetBorder}</p>}
        value={state.widgetBorder}
        onChange={(val) => setState(update(state, {widgetBorder: {$set: val}}))}
        output
      />
    </FormLayout>
  );
};

export default CardTab;